export const TutorFAQs = [
  {
    question: 'Do I have to have qualifications in the subjects I want to tutor in order to be a tutor on TuitionLab?',
    answer: 'Yes, and you should have qualifications to at least the level you intend to deliver',
  },
  {
    question: 'Do I have to have a DBS check to advertise on TuitionLab?',
    answer: 'Yes',
  },
  {
    question: 'What’s the best way to get a DBS check if I haven’t got one?',
    answer: 'Go to the <a href="https://www.gov.uk/government/organisations/disclosure-and-barring-service" target="_blank">UKGov website</a> and follow the instructions for requesting a basic DBS check',
  }, {
    question: 'Do TuitionLab remain involved once I’ve built the connection with my tutees?',
    answer: 'Yes but, only if we need to intervene for customer support',
  }, {
    question: 'Do TuitionLab take a cut of my earnings through tutoring? ',
    answer: 'No – we believe you should keep what you earn, our job is to connect quality tutors with motivated learners',
  },
];

export const TuteeFAQs = [
  {
    question: 'What happens if it doesn’t work out with a certain tutor?',
    answer: 'Unfortunately, this can and does happen for many reasons e.g. change of circumstances. Generally, the best way forward is to explore alternative tutors. If in the rare instance a tutor does not meet their agreed obligation to deliver your first free hour of tuition, then you should contact us at support@tuition-lab.com with details, we will refund you and penalise that specific tutor        ',
  },
  {
    question: 'What do I do if I have safeguarding concerns?',
    answer: 'Firstly, we require tutors to provide a basic DBS check in order to use our platform which is a thorough check of an individuals criminal record.Secondly, whether online or in person, you may wish to make specific arrangements with your tutor such as being present for the duration of the tutoring, or selecting specific venues in the case of in person tuition.',
  },
  {
    question: 'How can I tell which tutors on TuitionLab have been DBS checked?',
    answer: 'We will be rolling out a ‘DBS Verified’ badge for tutors’ profiles',
  }, {
    question: 'Am I able to see which qualifications the tutor has?',
    answer: 'Yes, on the tutor’s profile page',
  }, {
    question: 'Are your services purely for finding online tutors or can I find people that will teach in person too?',
    answer: 'Both. Our online platform helps you find tutors and make a connection, after which you make specific arrangements either online or in person with your tutor',
  }, {
    question: 'Am I able to find tutors that can provide help in more than one subject?',
    answer: 'Yes',
  }, {
    question: 'Are there discounts available for siblings?',
    answer: ' Not right now but we may offer such deals in future',
  },
];
