import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Search from '../../../assets/images/Search';

const SearchInput = ({ value, setValue }) => (
  <StyledForm role="search" method="post" onKeyDown={(event) => { if ((event.keyCode === 13)) { event.preventDefault(); } }}>
    <SearchBox
      type="search"
      className="search-field"
      placeholder="Search by topic..."
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
    <StyledSearchButton
      type="submit"
      className="search-submit button"
      value=""
    />
    <IconWrapper>
      <Search fill="#fff" />
    </IconWrapper>
  </StyledForm>
);

SearchInput.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

const IconWrapper = styled.div`
  position: absolute;
  right: 30px;
  top: 12px;
`;

const StyledSearchButton = styled.input`
  position: relative;
  width: 85px;
  height: 52px;
  right: 0;
  border-radius: 0px 59px 59px 0px;
  appearance: none;
  border: none;
  right: 0;
  position: absolute;
  transition: all 0.5s;
  background: #31d4d5;

  &:hover {
    background: #49c0c1;
  }
`;

const StyledForm = styled.form`
  position: relative;
`;

const SearchBox = styled.input`
  background-color: #f2f4f5;
  padding: 20px;
  border: none;
  margin-bottom: 0;
  flex: 1 0 auto;
  height: 51px;
  border-radius: 59px;
  width: 100%;
  padding: 0.375rem 1.25rem;
  font-family: Josefin Sans;
  font-weight: 300;
  color: #202020;
  font-size: 19px;
  padding: 26px;
}
`;

export default SearchInput;
