import React from 'react';

import Container from 'react-bulma-companion/lib/Container';
import styled from 'styled-components';

export default function ComingSoon() {
  return (
    <Section className="lost-section">
      <Container>
        <LandingHeader>Coming Soon!</LandingHeader>
        <LandingText>We are launching soon. Watch this space.</LandingText>
      </Container>
    </Section>
  );
}

const LandingHeader = styled.div`
  font-family: Josefin Sans;
  font-weight: 700;
  font-size: 96px;
  letter-spacing: -0.045em;
  color: #3c3c3c;
  text-align: left;
  margin-bottom: 30px;
  line-height: 104px;

  @media screen and (max-width: 757px) {
    font-size: 40px;
    line-height: unset;
  }

  ${(props) => props.white && 'color: #fff;'}
  ${(props) => props.centered && 'text-align: center;'}
`;

const LandingText = styled.h2`
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 23px;
  line-height: 30px;
  text-align: left;
  letter-spacing: -0.005em;

  ${(props) => props.centered && 'text-align: center;'}
`;

const Section = styled.div`
flex: 1;
margin-top: 100px;

@media only screen and (max-width: 600px) {
  .col {
    display: block;
    width: 100%;
  }
}
`;
