import React from 'react';
import Container from 'react-bulma-companion/lib/Container';
import styled from 'styled-components';
import { TuteeFAQs, TutorFAQs } from '../../../assets/faqs/faquestions';
import { LandingSubheading } from '../../atoms/Title/Titles';
import QAComponent from './QAComponent';

export default function LostPage() {
  return (
    <Section>
      <SectionWrapper className="faq-page page">
        <Container>
          <div>
            <LandingHeader>FAQs</LandingHeader>
            <LandingText>Got a question? You&apos;re in the right place.</LandingText>
          </div>
          <FAQ>
            <LandingSubheading>Tutor FAQs</LandingSubheading>
            <FAQContainer>
              {TutorFAQs.map((faq) => (
                <QAWrapper key={faq.question}>
                  <QAComponent faq={faq} />
                </QAWrapper>
              ))}
            </FAQContainer>
          </FAQ>
          <FAQ>
            <LandingSubheading>Tutee FAQs</LandingSubheading>
            <FAQContainer>
              {TuteeFAQs.map((faq) => (
                <QAWrapper key={faq.question}>
                  <QAComponent faq={faq} />
                </QAWrapper>
              ))}
            </FAQContainer>
          </FAQ>
        </Container>

      </SectionWrapper>
    </Section>
  );
}

const SectionWrapper = styled.section`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  min-height: 70vh;
  justify-content: center;
  margin: 30px;

  @media screen and (min-width: 757px) {
    justify-content: space-evenly;
  }

  ${(props) => props.short && 'min-height: 50vh;'}
  ${(props) => props.centered && 'justify-content: center;'}
  ${(props) =>
    props.blue && 'background-color: rgba(26,183,183, 0.1); margin: 0px;'}
`;

const LandingHeader = styled.div`
  font-family: Josefin Sans;
  font-weight: 700;
  font-size: 60px;
  letter-spacing: -0.045em;
  color: #3c3c3c;
  text-align: left;
  line-height: 104px;

  @media screen and (max-width: 757px) {
    font-size: 40px;
    line-height: unset;
  }

  ${(props) => props.white && 'color: #fff;'}
  ${(props) => props.centered && 'text-align: center;'}
`;

const QAWrapper = styled.div`
padding: 20px 0;
border-bottom: 1px solid #ccc;

&:last-child {
  border-bottom: none;
}
`;

const FAQ = styled.div`
margin: 40px 0;
`;

const FAQContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
`;

const LandingText = styled.h2`
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 23px;
  text-align: left;
  ${(props) => props.centered && 'text-align: center;'}
`;

const Section = styled.div`
flex: 1;
margin-top: 100px;

@media only screen and (max-width: 600px) {
  .col {
    display: block;
    width: 100%;
  }
}
`;
