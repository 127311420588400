/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import camelCaseKeys from 'camelcase-keys';
import { Link } from 'react-router-dom';
import DefaultPic from '../../../assets/images/default-profile.png';
import Globe from '../../../assets/images/Globe';

const TutorCard = ({
  tutor,
}) => {
  const { profilePic, firstName, location, headline, typeTags, hourlyRate } = camelCaseKeys(tutor);

  return (
    <Card>
      <StyledLink to={`/dashboard/profile/${tutor._id}`} target="_blank" rel="noopener noreferrer">
        <Content>
          <AvatarImage src={profilePic || DefaultPic} alt={firstName} />
          <React.Fragment>
            <TextContent>
              <FlexRow>
                <NameTitle>
                  {firstName || 'No name yet'}
                </NameTitle>
              </FlexRow>
              <HeadlineText>{headline || 'No bio yet'}</HeadlineText>
              <HeadlineText>
                <Globe />
                {location || 'No location yet'}
              </HeadlineText>
              <TagWrapper>
                {typeTags && typeTags.map((tag) => (
                  <TypeTagPill key={tag}>{tag}</TypeTagPill>
                ))}
              </TagWrapper>
            </TextContent>
          </React.Fragment>
        </Content>
        <ProfilePill>
          £
          {hourlyRate}
          <HourText>/hour</HourText>
        </ProfilePill>
        {/* <CTAWrapper>
          <Message />
        </CTAWrapper> */}
      </StyledLink>
    </Card>

  );
};

const StyledLink = styled(Link)`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;

  @media screen and (max-width: 464px) {
    justify-content: center;
    padding: 5px;
    flex-wrap: wrap;
  }
`;

// const CTAWrapper = styled.div`
//   display: flex;
//   align-self: flex-start;
//   margin: 15px;
//   position: absolute;
//   right: 50px;

//   svg {
//     margin-right: 5px;
//   }
// `;

const TagWrapper = styled.div`
  display: flex;
  fex-direction: row;
  margin: 10px 0;
`;

const Card = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  appearance: none;
  border: none;
  width: 100%;
  background: #ffffff;
  border-radius: 11px;
  box-shadow: 0px 4px 12px -6px rgba(0, 0, 0, 0.25);
  justify-content: space-between;
  min-height: 120px;
  flex-wrap: wrap;
  padding: 0;

  &:focus {
    outline: 3px solid #1ab7b7;
  }
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 11px 11px 0 0;
`;

const TextContent = styled.div`
  color: #3c3c3c;
  padding: 20px;
`;

const NameTitle = styled.div`
  font-weight: 900;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #3c3c3c;
  text-align: left;
  font-family: Josefin Sans;
`;

const HeadlineText = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 161.6%;
  display: flex;
  align-items: center;
  margin: 5px 0;
  text-align: left;
  text-overflow: ellipsis;
  max-height: 300px;
`;

const TypeTagPill = styled.div`
  width: 78px;
  height: 22px;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 11px;
  text-align: center;
  font-size: 9px;
  color: #3c3c3c;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

const ProfilePill = styled.span`
margin: 20px;
height: 29px;
background: #FFFFFF;
display: flex;
align-items: center;
justify-content: center;
font-family: 'Josefin Sans';
font-weight: 400;
font-size: 20px;
`;

const HourText = styled.div`
  color: #ababab;
  margin: 2px;
`;

export default TutorCard;
