import PropTypes from 'prop-types';
import React, { useEffect  } from 'react';
import styled from 'styled-components';
import camelCaseKeys from 'camelcase-keys';
import moment from 'moment';
import { getTutorById } from '_api/users';
import { putRequest } from '../../../api/requests';

const RequestCard = ({
  request,
  user,
  showPay,
  showAcceptReject,
  setShowPayModal,
  setRequestToPay,
  requestedTutor,
  setRequestedTutor,
  paid,
}) => {
  const { id, noOfWeeks, noOfHoursPerWeek, subject, amountDue, tutorId } = camelCaseKeys(request);
  const expiry = 14 - moment(request.createdAt).diff(moment(), 'days');
  const userTypeToUpdate = user.userType === 'Learner' ? 'tuteeStatus' : 'tutorStatus';

  useEffect(() => {
    if (!requestedTutor) {
      getTutorById(tutorId.toString()).then((res) => {
        setRequestedTutor(camelCaseKeys(res));
      });
    }
  }, []);

  const updateRequest = (newStatus) => {
    putRequest({ id, type: user.userType, status: newStatus });
    window.location.reload();
  };

  const redirectToCheckout = async () => {
    setRequestToPay(request);
    setShowPayModal(true);
  };

  if (expiry <= 0) return null;

  return (
    <React.Fragment>
      <Card>
        <Content>
          <React.Fragment>
            <TextContent>
              <FlexRow>
                <NameTitle>
                  {`${subject}`}
                  {requestedTutor && ` with ${requestedTutor.firstName}`}
                </NameTitle>
              </FlexRow>
              <HeadlineText>
                Number of Weeks:
                {' '}
                {noOfWeeks}
              </HeadlineText>
              <HeadlineText>
                Number of Hours per Week:
                {' '}
                {noOfHoursPerWeek}
              </HeadlineText>
              <HeadlineText>
                Tutor Request Status:
                {' '}
                {request.tutorStatus}
              </HeadlineText>
              <HeadlineText>
                Tutee Payment Status:
                {' '}
                {request.tuteeStatus}
              </HeadlineText>
              {paid && requestedTutor?.username && (
                <React.Fragment>
                  <HeadlineText blue>
                    <div>You can now book a lesson with your tutee!</div>
                  </HeadlineText>
                  <HeadlineText blue>
                    <div>
                      <a href={`mailto:${requestedTutor.username}`}>
                        {` Email: ${requestedTutor.username}`}
                      </a>
                    </div>
                  </HeadlineText>
                </React.Fragment>
              )}
              {!paid && request[userTypeToUpdate] !== 'Accepted' && user.userType === 'Tutor' && (
              <ExpiryText>
                Request expires in:
                {' '}
                {expiry}
                {' '}
                days
              </ExpiryText>
              )}
            </TextContent>
            <ButtonWrapper>
              {showAcceptReject && request[userTypeToUpdate] !== 'Accepted' && (
              <React.Fragment>
                <AcceptButton onClick={() => updateRequest('Accepted')}>Accept</AcceptButton>
                <RejectButton onClick={() => updateRequest('Rejected')}>Reject</RejectButton>
              </React.Fragment>
              )}
              {showPay && (
              <React.Fragment>
                <AcceptButton onClick={redirectToCheckout}>
                  {`Pay £${amountDue && amountDue}`}
                </AcceptButton>
                <RejectButton onClick={() => updateRequest('Rejected')}>Reject</RejectButton>
              </React.Fragment>
              )}
            </ButtonWrapper>
          </React.Fragment>
        </Content>
      </Card>
    </React.Fragment>
  );
};

RequestCard.propTypes = {
  request: PropTypes.object,
  user: PropTypes.object,
  showPay: PropTypes.bool,
  showAcceptReject: PropTypes.bool,
  setShowPayModal: PropTypes.func,
  setRequestToPay: PropTypes.func,
  requestedTutor: PropTypes.object.isRequired,
  setRequestedTutor: PropTypes.func.isRequired,
  paid: PropTypes.bool,
};

const ButtonWrapper = styled.div`
display: flex;
flex-direction: column;
margin: 10px 0;

@media screen and (max-width: 464px) {
  flex-direction: row;
}
`;

const AcceptButton = styled.button`
appearance: none;
border: none;
outline: none;
width: 120px;
height: 30px;
background: #1AB7B7;
border-radius: 20px;
display: flex;
justify-content: center;
align-items: center;
font-weight: 600;
font-size: 15px;
line-height: 161.6%;
display: flex;
align-self: flex-end;
color: #FFFFFF;
font-family: Josefin Sans;
cursor: pointer;
margin: 5px;
`;

const RejectButton = styled.button`
appearance: none;
border: 1px solid #1AB7B7;
outline: none;
width: 120px;
height: 30px;
background: #fff;
border-radius: 20px;
display: flex;
justify-content: center;
align-items: center;
font-weight: 600;
font-size: 15px;
line-height: 161.6%;
display: flex;
align-self: flex-end;
color: #1AB7B7;
font-family: Josefin Sans;
cursor: pointer;
margin: 5px;
`;

const FlexRow = styled.div`
display: flex;
flex-direction: row;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  width: 100%;

  @media screen and (max-width: 464px) {
    justify-content: left;
    padding: 5px;
    flex-wrap: wrap;
    flex-direction: row;
  }
`;

const Card = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  appearance: none;
  border: none;
  width: 100%;
  background: #ffffff;
  border-radius: 25px;
  box-shadow: 0px 4px 12px -6px rgba(0, 0, 0, 0.25);
  justify-content: space-between;
  min-height: 120px;
  margin-top: 15px;

  @media screen and (max-width: 464px) {
    flex-direction: column;
    padding: 15px;
    
  }

  &:focus {
    outline: 3px solid #1ab7b7;
  }
`;

const TextContent = styled.div`
  color: #3c3c3c;
`;

const NameTitle = styled.div`
  font-weight: 900;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #3c3c3c;
  text-align: left;
  font-family: Josefin Sans;
`;

const ExpiryText = styled.div`
  font-weight: 300;
  font-size: 14px;
  color: #ababab;
  line-height: 161.6%;
  display: flex;
  align-items: center;
  margin: 5px 0;
  text-align: left;
  font-weight: 600;
  font-family: Josefin Sans;

`;

const HeadlineText = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 161.6%;
  display: flex;
  align-items: center;
  margin: 5px 0;
  text-align: left;
  font-family: Josefin Sans;
  font-weight: 400;
  ${(props) => props.blue && 'color: #1ab7b7;'}
`;

export default RequestCard;
