import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Toggle = ({ name, onClick, selected }) => (
  <label className="switch">
    <StyledInput selected={selected} onClick={onClick}>{name}</StyledInput>
  </label>
);

Toggle.propTypes = {
  name: PropTypes.any.isRequired,
  onClick: PropTypes.any.isRequired,
  selected: PropTypes.any.isRequired,
};

const StyledInput = styled.button`
font-family: Josefin Sans;
transition: all 0.2s;
appearance: none;
border: none;
width: 120px;
padding: 10px;
font-weight: 800;
margin: 10px 10px 10px 0;
cursor: pointer;
border-radius: 18px;
${(props) => props.selected ? 'background: #1ab6b7; color:#fff;' : 'background: #999999;color: #fff'};

&:hover{
    box-shadow: -1px 4px 10px rgba(0, 0, 0, 0.2);
}
`;

export default Toggle;
