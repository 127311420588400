import styled from 'styled-components';
import Title from 'react-bulma-companion/lib/Title';

export const LandingHeading = styled.div`
  font-family: Josefin Sans;
  font-weight: normal;
  font-size: 96px;
  letter-spacing: -0.045em;
  color: #3c3c3c;
  text-align: left;
  margin-bottom: 30px;
  line-height: 104px;
  font-weight: 700;

  @media screen and (max-width: 757px) {
    font-size: 40px;
    line-height: unset;
  }
  ${(props) => props.size && `font-size: ${props.size}`}
  ${(props) => props.white && 'color: #fff;'}
  ${(props) => props.centered && 'text-align: center;'}
`;

export const LandingMiniText = styled.div`
  font-family: Josefin Sans;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: #3c3c3c;
  text-align: left;
  text-transform: uppercase;
`;

export const LandingText = styled(Title)`
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 23px;
  line-height: 30px;
  text-align: left;
  letter-spacing: -0.005em;
  ${(props) => props.centered && 'text-align: center;'}
`;

export const LandingSubheading = styled.div`
  font-family: Josefin Sans;
  font-size: 35px;
  line-height: 39px;
  font-weight: 900;
  ${(props) => props.size && `font-size: ${props.size}`}
`;

export const EditProfileHeading = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 23px;
  text-align: left;
  ${(props) => props.centered && 'text-align: center;'}
`;
