import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import R from 'ramda';

import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';

import Checkbox from 'react-bulma-companion/lib/Checkbox';

import useKeyPress from '_hooks/useKeyPress';
import { attemptLogin } from '_thunks/auth';
import styled from 'styled-components';

import {
  LandingHeading,
  LandingText,
  LandingMiniText,
} from '../../atoms/Title/Titles';

import { LandingButton } from '../../atoms/Buttons/Buttons';

export default function Login() {
  const dispatch = useDispatch();

  const [remember, setRemember] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const username = localStorage.getItem('username');
    if (username) {
      setRemember(true);
      setUsername(username);
    }
  }, []);

  const login = () => {
    const userCredentials = { username, password };

    if (remember) {
      localStorage.setItem('username', username);
    }

    dispatch(attemptLogin(userCredentials)).catch(R.identity);
  };

  useKeyPress('Enter', login);

  const rememberMe = () => {
    localStorage.removeItem('username');
    setRemember(!remember);
  };

  const updateUsername = (e) => setUsername(e.target.value);
  const updatePassword = (e) => setPassword(e.target.value);

  return (
    <Wrapper>
      <Content>
        <HeadingWrapper>
          <LandingHeading>Login</LandingHeading>
          <LandingText>Get notified when we launch.</LandingText>
          <LandingMiniText>
            Not registered yet?&nbsp;
            <Link to="/register">Create an account.</Link>
          </LandingMiniText>
        </HeadingWrapper>

        <FieldWrapper>
          <StyledInput
            onChange={updateUsername}
            placeholder="Username"
            value={username}
            leftIcon={faUser}
          />
          <StyledInput
            onChange={updatePassword}
            placeholder="Password"
            value={password}
            leftIcon={faLock}
            type="password"
          />
        </FieldWrapper>

        {/* <MiniText>
            <Link to="/recovery">Forgot your password?</Link>
          </MiniText> */}
        <LandingButton onClick={login}>Login</LandingButton>
        <RememberMeText>
          <Checkbox>
            <input type="checkbox" onChange={rememberMe} checked={remember} />
            &nbsp; Remember me
          </Checkbox>
        </RememberMeText>
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
`;

const Content = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 2rem;
  width: 100%;
  margin-top: 10vh;

  @media screen and (min-width: 767px) {
    max-width: 40vw;
  }
`;

const StyledInput = styled.input`
  padding: 2rem;
  margin-bottom: 0.75rem;
  font-family: Josefin Sans;
  font-weight: 300;
  font-size: 2rem;
  border: none;
  box-shadow: none;
  border-bottom: 1px solid #ccc;
  color: #363636;

  &:focus {
    outline: none;
    border-bottom: 1px solid #28a2a2;
  }

  @media screen and (max-width: 676px) {
    font-size: 1rem;
  }
`;

const RememberMeText = styled.span`
  margin: 2rem 0;
  color: black;

  input[type="checkbox"] {
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid currentColor;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
  }
`;

const FieldWrapper = styled.div`
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
`;

const HeadingWrapper = styled.div`
  margin-bottom: 2rem;
`;
