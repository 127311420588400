/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import R from 'ramda';
import Section from 'react-bulma-companion/lib/Section';
import styled from 'styled-components';
import { getRequests, putRequest } from '_api/requests';
// import ProfilePreview from '_organisms/ProfilePreview/ProfilePreview.jsx';
import RequestCard from '_molecules/RequestCard/RequestCard';
import moment from 'moment';
import { useElements, useStripe, CardElement } from '@stripe/react-stripe-js';
import { LandingSubheading } from '../../atoms/Title/Titles';
// import SortBy from "../../molecules/SortBy/SortBy.jsx";
import { EmptyResults } from '../../molecules/EmptyResults/EmptyResults';

export const RequestsPage = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(R.pick(['user']));
  const [requestsList, setRequestsList] = useState(null);
  const { userType } = user;
  const [showPayModal, setShowPayModal] = useState(false);
  const [requestToPay, setRequestToPay] = useState(null);
  const [requestedTutor, setRequestedTutor] = useState(null);
  const [error, setError] = useState(null);

  const elements = useElements();
  const stripe = useStripe();

  const updateRequest = ({ id, statusToUpdate }) => {
    putRequest({ id, type: user.userType, status: statusToUpdate });
    window.location.reload();
  };

  useEffect(() => {
    if (R.isEmpty(user)) {
      dispatch(push('/login'));
    }
  }, []);

  useEffect(() => {
    if (!requestsList) {
      getRequests()
        .then((res) => {
          setRequestsList(res.requests);
        });
    }
  }, [requestsList]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { clientSecret } = await fetch('/api/requests/create-payment-intent', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ amount: requestToPay.amountDue }),
    }).then(res => res.json());

    const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (stripeError) {
      // eslint-disable-next-line no-alert
      setError(stripeError.message);
      return;
    }

    const requestId = requestToPay._id;

    if (paymentIntent.status === 'succeeded') {
      setError(null);
      updateRequest({ id: requestId, statusToUpdate: 'Paid' });
      window.location.reload();
    }
  };

  return (
    <React.Fragment>
      <SectionWrapper className="p-0">
        <SearchArea>
          <LandingSubheading>
            {user.userType === 'Learner' ? 'My Tutor Requests' : 'My Tutee Requests'}
          </LandingSubheading>
          <WelcomeText>Here are your active requests.</WelcomeText>
          <Wrapper noResults={!requestsList || requestsList.length === 0}>
            {!requestsList || requestsList.length === 0 ? <WelcomeText>No requests at the moment.</WelcomeText>
              : (
                <React.Fragment>
                  <RequestSectionWrapper>
                    <HeadingTitle>Pending Requests</HeadingTitle>
                    <Results>
                      {requestsList && requestsList.length > 0 ? requestsList.map((request) => (
                        (request.tutorStatus === 'Pending') && (14 - moment(request.createdAt).diff(moment(), 'days')) > 0)
                        && (
                          <RequestCard
                            key={request.id}
                            requestedTutor={requestedTutor}
                            request={request}
                            user={user}
                            showAcceptReject={user.userType === 'Tutor'}
                            setRequestedTutor={setRequestedTutor}
                          />
                        ),
                      ) : <EmptyResults />}
                    </Results>
                  </RequestSectionWrapper>
                  <RequestSectionWrapper>
                    <HeadingTitle>Accepted Requests</HeadingTitle>
                    {userType === 'Tutor' && (
                      <Results>
                        {requestsList && requestsList.length > 0 ? requestsList.map((request) => (
                          request.tutorStatus === 'Accepted' && request.tuteeStatus === 'Pending'
                          && (
                            <RequestCard
                              key={request.id}
                              requestedTutor={requestedTutor}
                              setRequestedTutor={setRequestedTutor}
                              request={request}
                              user={user}
                              showAcceptReject={false}
                            />
                          )
                        )) : <EmptyResults />}
                      </Results>
                    )}
                    {userType === 'Learner' && (
                      <Results>
                        {requestsList && requestsList.length > 0 ? requestsList.map((request) => (
                          request.tutorStatus === 'Accepted' && request.tuteeStatus === 'Pending'
                          && (
                            <RequestCard
                              key={request.id}
                              requestedTutor={requestedTutor}
                              setRequestedTutor={setRequestedTutor}
                              request={request}
                              user={user}
                              showPay={true}
                              showAcceptReject={false}
                              setShowPayModal={setShowPayModal}
                              setRequestToPay={setRequestToPay}
                            />
                          )
                        )) : <EmptyResults />}
                      </Results>
                    )}
                  </RequestSectionWrapper>
                  <RequestSectionWrapper>
                    <HeadingTitle>Completed Requests</HeadingTitle>
                    <Results>
                      {requestsList && requestsList.length > 0 ? requestsList.map((request) => (
                        request.tutorStatus === 'Accepted' && request.tuteeStatus === 'Paid'
                        && (
                          <RequestCard
                            key={request.id}
                            setRequestedTutor={setRequestedTutor}
                            requestedTutor={requestedTutor}
                            request={request}
                            user={user}
                            paid={true}
                          />
                        )
                      )) : <EmptyResults />}
                    </Results>
                  </RequestSectionWrapper>
                </React.Fragment>
              )}

          </Wrapper>
        </SearchArea>
      </SectionWrapper>
      {showPayModal && (
        <PaymentModal>
          <PaymentModalContent>
            <PaySubheading>
              {`Pay £${requestToPay.amountDue} for your first lesson with ${requestToPay.tuteeEmail && requestToPay.tuteeEmail}`}
            </PaySubheading>
            <PaymentForm id="payment-form" onSubmit={handleSubmit}>
              <label htmlFor="card-element">Card</label>
              <CardElement />
              <HelpText>{error && error}</HelpText>
            </PaymentForm>
            <ButtonWrapper>
              <AcceptButton onClick={handleSubmit}>Pay</AcceptButton>
              <RejectButton onClick={() => setShowPayModal(false)}>Close</RejectButton>
            </ButtonWrapper>
          </PaymentModalContent>
        </PaymentModal>
      )}
    </React.Fragment>
  );
};

const HelpText = styled.div`
  margin: 5px 0;
  display: block;
`;

const PaySubheading = styled.div`
  font-family: Josefin Sans;
  font-size: 20px;
  line-height: 39px;
  font-weight: 900;
`;

const PaymentForm = styled.form`
  padding: 20px 0;
  width: 100%;
  height: 100%;

  label {
    visibility: hidden;
    margin: 10px;
  }
 `;

const PaymentModalContent = styled.div`
  margin: auto;
  width: 30%;
  background-color: white;
  border-radius: 1.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 3rem;

  @media screen and (max-width: 1024px) {
    width: 50%;
  }

  @media screen and (max-width: 757px) {
    width: 100vw;
    height: 100vh;
  }
`;

const PaymentModal = styled.section`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0,0,0,0.4); /* <-- this is the culprit */
  display: flex;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

const ButtonWrapper = styled.div`
  justify-content: flex-end;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 757px) {
    justify-content: center;
  }`;

const HeadingTitle = styled.div`
  font-size: 3rem;
  font-weight: 400;
  margin: 20px 0;
  font-size: 25px;
  line-height: 161.6%;
  display: flex;
  align-items: center;
  color: #3C3C3C;
  `;

const AcceptButton = styled.button`
  margin: 20px 0;
  appearance: none;
  border: 1px solid #1AB7B7;
  outline: none;
  width: 120px;
  height: 30px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
  line-height: 161.6%;
  display: flex;
  color: #1AB7B7;
  font-family: Josefin Sans;
  cursor: pointer;
  background: #1AB7B7;
  color:#fff;
  margin-right: 10px;
`;

const RejectButton = styled.button`
  margin: 20px 0;
  appearance: none;
  border: 1px solid #1AB7B7;
  outline: none;
  width: 120px;
  height: 30px;
  background: #fff;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
  line-height: 161.6%;
  display: flex;
  color: #1AB7B7;
  font-family: Josefin Sans;
  cursor: pointer;
`;

const RequestSectionWrapper = styled.div`
  font-family: Josefin Sans;
  margin: 20px 0;
`;

const Wrapper = styled.div`
  margin: 50px 0;
  ${({ noResults }) => noResults && `
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;`}
`;

const Results = styled.div`
  font-family: Josefin Sans;  
`;

const WelcomeText = styled.div`
  font-family: 'Josefin Sans';
  font-weight: 400;
  color: #ababab;
  font-size: 24px;
  margin: 10px 0;
`;

const SectionWrapper = styled(Section)`
  display: flex;
  width: 100%;
  flex-direction: row;
`;

const SearchArea = styled.div`
  flex: 1;
  margin-top: 50px;

  @media only screen and (max-width: 600px) {
    .col {
      display: block;
      width: 100%;
    }
  }
`;

export default RequestsPage;
