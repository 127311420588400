import React from "react";
import styled from 'styled-components'

const Globe = () => {
    return (
        <GlobeIcon width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 1C3.24 1 1 3.24 1 6C1 8.76 3.24 11 6 11C8.76 11 11 8.76 11 6C11 3.24 8.76 1 6 1ZM2 6C2 5.695 2.04 5.395 2.105 5.11L4.495 7.5V8C4.495 8.55 4.945 9 5.495 9V9.965C3.53 9.715 2 8.035 2 6ZM8.945 8.7C8.815 8.295 8.445 8 7.995 8H7.495V6.5C7.495 6.225 7.27 6 6.995 6H3.995V5H4.995C5.27 5 5.495 4.775 5.495 4.5V3.5H6.495C7.045 3.5 7.495 3.05 7.495 2.5V2.295C8.96 2.885 10 4.325 10 6C10 7.04 9.595 7.99 8.945 8.7Z" fill="#3C3C3C" />
        </GlobeIcon>
    );
};


const GlobeIcon = styled.svg`
margin-right: 5px;
`

export default Globe;
