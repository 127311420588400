import React, { useState, useEffect } from 'react';
import Section from 'react-bulma-companion/lib/Section';
import styled from 'styled-components';
import camelCaseKeys from 'camelcase-keys';
import { LandingSubheading } from '_atoms/Title/Titles';
import { useParams } from 'react-router-dom';
import { getTutorById } from '_api/users';
import moment from 'moment';
import { postRequest } from '_api/requests';
import R from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import { store as RNC } from 'react-notifications-component';
import { push } from 'connected-react-router';
import { useMediaQuery } from 'react-responsive';
import DefaultPic from '../../../assets/images/default-profile.png';

export const TutorProfilePage = () => {
  const { id } = useParams();
  const [tutor, setTutor] = useState();
  const dispatch = useDispatch();
  const { user } = useSelector(R.pick(['user']));
  const [noOfWeeks, setNoOfWeeks] = useState(2);
  const [noOfHoursPerWeek, setNoOfHoursPerWeek] = useState(6);
  const [requestSubject, setRequestSubject] = useState();
  const [needsHelp, setNeedsHelp] = useState(false);
  const [cantBookYourself, setCantBookYourself] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  useEffect(() => {
    if (R.isEmpty(user)) {
      dispatch(push('/login'));
    }
  }, []);

  useEffect(() => {
    if (!tutor) {
      getTutorById(id.toString()).then((res) => {
        setTutor(camelCaseKeys(res));
      });
    }
  }, []);

  const onSubmitRequest = () => {
    setNeedsHelp(false);
    setCantBookYourself(false);
    if (!noOfWeeks || !noOfHoursPerWeek || !requestSubject) {
      setNeedsHelp(true);
    } else if (tutor.id === user.id) {
      setCantBookYourself(true);
    } else {
      postRequest({
        tutorId: tutor.id,
        tuteeId: user.id,
        noOfHoursPerWeek: noOfWeeks,
        noOfWeeks: noOfHoursPerWeek,
        subject: requestSubject,
        tutorStatus: 'Pending',
        tuteeStatus: 'Pending',
        amountDue: tutor.hourlyRate,
        tuteeEmail: user.username,
        tuteePaid: false,
      });

      setRequestSubject('');
      setNoOfHoursPerWeek(0);
      setNoOfWeeks(0);

      RNC.addNotification({
        title: 'Success!',
        message: 'Request sent!',
        type: 'success',
        container: 'top-right',
        animationIn: ['animated', 'fadeInRight'],
        animationOut: ['animated', 'fadeOutRight'],
        dismiss: {
          duration: 5000,
        },
      });
    }
  };

  return (
    <SectionWrapper className="p-0" isTabletOrMobile={isTabletOrMobile}>
      <LandingSubheading>
        {tutor?.firstName}
        &apos;s Profile
      </LandingSubheading>
      <ProfileWrapper>
        <Content>
          <HeadingWrapper>
            <Avatar src={tutor?.profilePic || DefaultPic} />
            <HeadingText>
              <HeadingName>{tutor?.firstName}</HeadingName>
              <Bio size="18px">{tutor?.headline}</Bio>
              <Bio size="18px">
                Member since
                {' '}
                {moment(tutor?.createdAt).format('DD/MM/YYYY')}
              </Bio>
              {tutor?.typeTags && (
                <TagWrapper>
                  {tutor.typeTags.map((tag) =>
                    <ProfilePill key={tag}>{tag}</ProfilePill>,
                  )}
                </TagWrapper>
              )}
            </HeadingText>
          </HeadingWrapper>
          <ColourWrapper>
            <HeadingTitle>
              About
              {' '}
              {tutor?.firstName}
            </HeadingTitle>
            <Bio size="18px">{tutor?.bio}</Bio>
          </ColourWrapper>
          <SkillsLangGrid>
            <Item>
              <HeadingTitle>Subjects Taught</HeadingTitle>
              {tutor?.skills && (
                <SkillsLangItem>
                  {tutor.skills.map(({ text }) =>
                    <ProfilePill key={text}>{text}</ProfilePill>,
                  )}
                </SkillsLangItem>
              )}
            </Item>
            <Item>
              <HeadingTitle>Languages Spoken</HeadingTitle>
              {tutor?.languages && (
                <SkillsLangItem>
                  {tutor.languages.map(({ text }) =>
                    <ProfilePill key={text}>{text}</ProfilePill>,
                  )}
                </SkillsLangItem>
              )}
            </Item>
          </SkillsLangGrid>
        </Content>
      </ProfileWrapper>
      {user.userType === 'Learner'
        && (
          <RequestArea>
            <LandingSubheading size="36px">
              Book a course with
              {' '}
              {tutor?.firstName}
            </LandingSubheading>

            <RequestInputs>
              <RequestInputWrapper>
                <RequestLabel>SUBJECT</RequestLabel>
                <RequestInput
                  value={requestSubject}
                  id="requestSubject"
                  placeholder="English A Level"
                  type="string"
                  onChange={(e) => setRequestSubject(e.target.value)}
                />
              </RequestInputWrapper>
              <RequestInputWrapper>
                <RequestLabel>Hours per week</RequestLabel>
                <RequestInput
                  small
                  value={noOfHoursPerWeek || 2}
                  id="hoursPerWeek"
                  placeholder={2}
                  type="number"
                  min="0"
                  onChange={(e) => setNoOfHoursPerWeek(e.target.value)}
                />
              </RequestInputWrapper>
              <RequestInputWrapper>
                <RequestLabel>No. of weeks</RequestLabel>
                <RequestInput
                  small
                  min="0"
                  value={noOfWeeks || 6}
                  id="numberOfWeeks"
                  placeholder={5}
                  type="number"
                  onChange={(e) => setNoOfWeeks(e.target.value)}
                />

              </RequestInputWrapper>
              <RequestButton
                // disabled={!requestSubject || !noOfHoursPerWeek || !noOfWeeks}
                onClick={() => onSubmitRequest()}
              >
                Send Request
              </RequestButton>
            </RequestInputs>
            <HelpWrapper>
              {needsHelp && <HelpText>Please complete all inputs</HelpText>}
              {cantBookYourself
                && <HelpText>Did you mean to request a course with yourself?</HelpText>}
            </HelpWrapper>
          </RequestArea>
        )}
    </SectionWrapper>

  );
};

const HelpWrapper = styled.div` 
  display: flex;
`;

const HelpText = styled.div`
  margin: 5px 0;
  display: block;
`;
const RequestButton = styled.button`
  appearance: none;
  border: none;
  outline: none;
  width: 212px;
  height: 54px;
  background: #1AB7B7;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 21px;
  line-height: 161.6%;
  display: flex;
  align-self: flex-end;
  color: #FFFFFF;
  margin: 10px 30px 0px 0;
  font-family: Josefin Sans;
  cursor: pointer;
`;

const RequestInputs = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 20px 0;
`;

const RequestInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 30px 0px 0;
`;

const RequestInput = styled.input`
  height: 54px;
  font-family: 'Josefin Sans';
  font-size: 20px;
  padding: 10px;
  border: none;
  ${({ small }) => small && 'width: 70px;'}
`;

const RequestLabel = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 161.6%;
  display: flex;
  align-items: center;
  color: #858585;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

const Item = styled.span`
`;

const SkillsLangItem = styled.div`
  min-height: 153px;
  background: #EDF7F7;
  border-radius: 10px;
  padding: 20px;
`;

const SkillsLangGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin: 50px 0;

  @media screen and (max-width: 757px) {
    grid-template-columns: 1fr;
  }
`;

const RequestArea = styled.div`
  background: #F8F8F8;
  border-radius: 10px;
  min-height: 200px;
  margin: 20px 0;
  padding: 40px;
`;

const HeadingTitle = styled.div`
  font-size: 3rem;
  font-weight: 600;
  margin: 20px 0;
  font-weight: 700;
  font-size: 25px;
  line-height: 161.6%;
  display: flex;
  align-items: center;
  color: #3C3C3C;
`;

const HeadingText = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #F8F8F8;
  height: 100%;

  border-radius: 10px;
  padding: 25px;
  flex: 1;
`;

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  padding: 40px 0;
  margin: 20px 0;
  flex-wrap: wrap;
`;

const ColourWrapper = styled.div`
  color: #2b7f7f;
  text-align: justify;
`;

const HeadingName = styled(LandingSubheading)`
  font-size: 36px;
  margin-bottom: 10px;
`;

const SectionWrapper = styled(Section)`
  flex: 1;
  display: flex;
  flex-direction: column;
  font-family: Josefin Sans;
  margin-top: 50px;
  ${({ isTabletOrMobile }) => isTabletOrMobile && 'margin: 40px;'};
`;

const Bio = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 161.6%;
  ${({ size }) => size && `font-size: ${size}`};
  margin-bottom: 10px;
  color: #3D3D3D;
  font-weight: 400;
  line-height: 193.6%;
  text-align: justify;
`;

const ProfileWrapper = styled.div`
  transition: width 0.5s;
  display: flex;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 100%;
`;

const Avatar = styled.img`
  height: 100%;
  width: 256px;
  border-radius: 10px;
  margin-right: 30px;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  max-height: 300px;
`;

const ProfilePill = styled.span`
  min-width: 106px;
  height: 37px;
  background: #1AB7B7;
  border-radius: 27px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  padding: 3px 5px 0px 5px;
  color: #FFFFFF;
  margin: 5px 10px 5px 0;
  font-size: 18px;
`;

const TagWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export default TutorProfilePage;
