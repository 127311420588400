import React from 'react';
import { Link,  useLocation } from 'react-router-dom';
import styled from 'styled-components';
// import Saved from "../../../assets/images/Saved.js";
// import Messages from "../../../assets/images/Messages.js";
import LogoStyled from '_atoms/Logo/Logo';
import { useMediaQuery } from 'react-responsive';
import Home from '../../../assets/images/Home';
import Profile from '../../../assets/images/Profile';
import Activity from '../../../assets/images/Activity';
import Search from '../../../assets/images/Search';

const LeftNavigation = () => {
  const location = useLocation();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  if (!(location.pathname).includes('/dashboard/')) return null;

  return (
    <BiggerWrapper>
      <Wrapper isTabletOrMobile={isTabletOrMobile}>
        <Link to="/">
          {!isTabletOrMobile && <LogoStyled dark large>tuitionlab</LogoStyled>}
        </Link>
        <StyledList isTabletOrMobile={isTabletOrMobile}>
          {/* <Link to="/">
            <NavItem>
              <NavIcon>
                <Home fill={isActuallyHome ? '#fff' : '#c4c3c6'} />
              </NavIcon>
              dashboard
            </NavItem>
          </Link> */}
          <Link to="/home">
            <NavItem isTabletOrMobile={isTabletOrMobile} isSelected={location.pathname === '/home'}>
              <NavIcon>
                <Home fill={location.pathname === '/home' ? '#fff' : '#c4c3c6'} />
              </NavIcon>
              {!isTabletOrMobile && 'home'}
            </NavItem>
          </Link>

          <Link to="/dashboard/search">
            <NavItem isTabletOrMobile={isTabletOrMobile} isSelected={location.pathname === '/dashboard/search'}>
              <NavIcon>
                <Search fill={location.pathname === '/dashboard/search' ? '#fff' : '#c4c3c6'} />
              </NavIcon>
              {!isTabletOrMobile && 'tutor search'}
            </NavItem>
          </Link>
          {/* <NavItem>
          <NavIcon>
            <Saved />
          </NavIcon>
          saved
        </NavItem> */}
          <Link to="/dashboard/requests">
            <NavItem isTabletOrMobile={isTabletOrMobile} isSelected={location.pathname === '/dashboard/requests'}>
              <NavIcon>
                <Activity fill={location.pathname === '/dashboard/requests' ? '#fff' : '#c4c3c6'} />
              </NavIcon>
              {!isTabletOrMobile && 'requests'}
            </NavItem>
          </Link>
          {/* <NavItem> */}
          {/* <NavIcon>
          <Messages />
        </NavIcon>
        messages
      </NavItem> */}
          <Link to="/dashboard/settings">
            <NavItem isTabletOrMobile={isTabletOrMobile} isSelected={location.pathname === '/dashboard/settings'}>
              <NavIcon>
                <Profile fill={location.pathname === '/dashboard/settings' ? '#fff' : '#c4c3c6'} />
              </NavIcon>
              {!isTabletOrMobile && 'profile'}
            </NavItem>
          </Link>
        </StyledList>
      </Wrapper>
    </BiggerWrapper>
  );
};

const BiggerWrapper = styled.div`
min-height: 100vh;
`;

const Wrapper = styled.div`
width: 270px;
margin-top: 40px;
margin-left: 3vw;
${({ isSelected }) => isSelected && 'color: #fff;'}

${({ isTabletOrMobile }) => isTabletOrMobile && `
  width: 100px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 20px;
  padding: 0;
  bottom: 0;
  width: 100vw;
  height: 70px;
  position: fixed;
  justify-content: center
  position: fixed;
  left: 0;
`};
`;

const StyledList = styled.ul`
margin-top: 100px;
${({ isTabletOrMobile }) => isTabletOrMobile && `
display: flex;
margin-top: 0;
justify-content: space-evenly;
width: 100%;
align-items: center;
padding: 0px 15px;
height: 100%;
`}`;

const NavItem = styled.li`
  margin: 17px 0;
  font-family: Josefin Sans;
  font-weight: 500;
  color: #c4c3c6;
  font-size: 18px;
  line-height: 40px;
  cursor: pointer;
  ${({ isSelected }) => isSelected && 'color: #fff;'}
  ${({ isSelected }) => isSelected && `background: #363636;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;`}
  padding: 5px 0px;
  font-weight: 700;
  padding-bottom: 10px;
  margin-right: 10px;
  border-radius: 10px;

  ${({ isTabletOrMobile }) => isTabletOrMobile && `
  margin: 0;
  padding: 0px 15px;
  `}
`;

const NavIcon = styled.span`
margin: 0 15px;

@media screen and (max-width: 1024px) {
  margin-right: 10px;
}

svg {
  position: relative;
  top: 12px;
}
`;

export default LeftNavigation;
