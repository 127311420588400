/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import R from 'ramda';
import Section from 'react-bulma-companion/lib/Section';
import styled from 'styled-components';
import { getUserList, searchTutors } from '_api/users';
// import ProfilePreview from '_organisms/ProfilePreview/ProfilePreview.jsx';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { LandingSubheading } from '../../atoms/Title/Titles';
import SearchInput from '../../atoms/SearchInput/SearchInput';
// import SortBy from "../../molecules/SortBy/SortBy.jsx";
import TutorCard from '../../molecules/TutorCard/TutorCard';
import { EmptyResults } from '../../molecules/EmptyResults/EmptyResults';

export const SearchPage = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(R.pick(['user']));
  const [searchInput, setSearchInput] = useState('');
  // const [sortBy, setSortBy] = useState("Popularity");
  const [tutorList, setTutorList] = useState([]);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 757px)' });

  useEffect(() => {
    if (R.isEmpty(user)) {
      dispatch(push('/login'));
    }
  }, []);

  useEffect(() => {
    if (searchInput === '') {
      getUserList()
        .then((res) => {
          setTutorList(res);
        });
    } else {
      searchTutors(searchInput)
        .then((res) => {
          setTutorList(res);
        });
    }
  }, [searchInput]);

  return (
    <SectionWrapper className="p-0">
      <SearchArea>
        <LandingSubheading>
          {user.userType === 'Learner' ? 'Find a Tutor' : 'My Account'}
          {user.firstName ? (
            <WelcomeText>
              {`Welcome, ${user.firstName}!`}
            </WelcomeText>
          ) : (
            <WelcomeText>
              Welcome! Get started by
              {' '}
              <Link to="/dashboard/settings">completing your profile</Link>
              .
            </WelcomeText>
          )}
        </LandingSubheading>
        <Wrapper>
          <SearchInput value={searchInput} setValue={setSearchInput} />
          {/* <SortBy sortValue={sortBy} setSortValue={setSortBy} /> */}
          <Results isTabletOrMobile={isTabletOrMobile}>
            {tutorList && tutorList.length > 0 ? tutorList.map((tutor) => {
              if (tutor.first_name && tutor.headline) {
                return (
                  <TutorCard
                    key={tutor._id}
                    tutor={tutor}
                  />
                );
              } return null;
            }) : <EmptyResults />}
          </Results>
        </Wrapper>
      </SearchArea>
    </SectionWrapper>
  );
};

const Wrapper = styled.div`
  margin: 50px 0;
`;

const Results = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin: 20px 0;
  ${({ isTabletOrMobile }) => isTabletOrMobile && 'grid-template-columns: 1fr'};
`;

const WelcomeText = styled.div`
  font-family: 'Josefin Sans';
  font-weight: 400;
  color: #ababab;
  font-size: 24px;
  margin: 10px 0;
`;

const SectionWrapper = styled(Section)`
  display: flex;
  width: 100%;
  flex-direction: row;
`;

const SearchArea = styled.div`
  flex: 1;
  margin-top: 50px;
  `;

export default SearchPage;
