import styled from 'styled-components';

export const SortMiniText = styled.span`
  font-family: Josefin Sans;
  font-size: 15px;
  line-height: 25px;
  color: #000000;
`;

export const ProfileSubtitle = styled.div`
  font-family: Josefin Sans;
  font-weight: 400;
  font-size: 18px;
  line-height: 161.6%;
  color: #000000;
  margin: 20px 0;
  font-size: 25px;
  line-height: 161.6%;
  display: flex;
  align-items: center;
  color: #3C3C3C;
`;

export const EditProfileSubtitle = styled.div`
  font-family: Josefin Sans;
  font-weight: 500;
  font-size: 18px;
  text-align: left;
  color: #3c3c3c;
`;
