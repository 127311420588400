import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import R from 'ramda';
import { attemptLogout } from '_thunks/auth';

import Navbar from 'react-bulma-companion/lib/Navbar';
import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';
import UserDropdown from '_molecules/UserDropdown';
import { useMediaQuery } from 'react-responsive';
import Home from '../../../assets/images/Home';
import LogoStyled from '../../atoms/Logo/Logo';
import Profile from '../../../assets/images/Profile';
import Activity from '../../../assets/images/Activity';
import Search from '../../../assets/images/Search';
import { Burger } from '../../../assets/icons/burger';
import BurgerMenuPage from '../BurgerMenuPage/BurgerMenuPage';

const noScroll = require('no-scroll');

export default function Navigation({ pathname, isTabletOrMobile }) {
  const { user } = useSelector(R.pick(['user']));
  const dispatch = useDispatch();
  const showBurgerMenu = useMediaQuery({ query: '(max-width: 1024px)' });
  const [isBurgerMenuVisible, setIsBurgerMenuVisible] = useState(false);
  const [auth, setAuth] = useState(!R.isEmpty(user));
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setAuth(!R.isEmpty(user));
  }, [user.username]);

  const logout = () => {
    dispatch(attemptLogout()).catch(R.identity);
  };

  const closeDropdown = () => setOpen(false);

  // const isHome = pathname.length === 5
  //   ? pathname === '/'
  //   : R.slice(0, 6, pathname) === '/home';

  // const isTodo =
  //   pathname.length === 5
  //     ? pathname === "/todo"
  //     : R.slice(0, 6, pathname) === "/todo/";

  // const isSettings =
  //   pathname.length === 9
  //     ? pathname === "/settings"
  //     : R.slice(0, 10, pathname) === "/settings/";

  if ((pathname).includes('/dashboard/')) return null;

  return (
    <React.Fragment>
      {isBurgerMenuVisible && <BurgerMenuPage setIsBurgerMenuVisible={setIsBurgerMenuVisible} />}
      <NavbarStyled>
        <Navbar.Item
          to={auth ? '/home' : '/'}
          aria-label="main navigation"
          component={Link}
        >
          <LogoStyled>tuitionlab</LogoStyled>
        </Navbar.Item>
        {/* <div className="navbar-brand-right">
            {!auth && (
              <Navbar.Item
                className="is-hidden-desktop"
                to="/login"
                component={Link}
              >
                <Title size="6">Login</Title>
              </Navbar.Item>
            )}
            {!auth && (
              <Navbar.Item
                className="is-hidden-desktop"
                to="/register"
                component={Link}
              >
                <Button color="success">Sign Up</Button>
              </Navbar.Item>
            )}
            {auth && (
              <Navbar.Item
                className="is-hidden-desktop"
                onClick={toggleDropdown}
                onKeyPress={toggleDropdown}
                hoverable
                component="a"
              >
                <Image size="32x32">
                  <Image.Content
                    className="profile-img"
                    src={user.profilePic || "/images/default-profile.png"}
                  />
                </Image>
                <span className="dropdown-caret" />
              </Navbar.Item>
            )}
          </div> */}
        {/* </Navbar.Brand> */}
        {/* {auth ? ( */}
        {/* <Navbar.Menu>
        <Navbar.Start>
        <Navbar.Item
          className="is-hidden-mobile"
          to="/home"
          active={isHome}
          tab
          component={Link}
        >
          <Title size="6">Home</Title>
        </Navbar.Item> */}
        {/* <Navbar.Item
                className="is-hidden-mobile"
                to="/todo"
                active={isTodo}
                tab
                component={Link}
              >
                <Title size="6">Todo</Title>
              </Navbar.Item> */}
        {/* <Navbar.Item
                className="is-hidden-mobile"
                to="/settings"
                active={isSettings}
                tab
                component={Link}
              >
                <Title size="6">Settings</Title>
              </Navbar.Item> */}
        {/* </Navbar.Start> */}
        {/* <Navbar.End> */}
        {/* <Navbar.Item
              onClick={toggleDropdown}
              onKeyPress={toggleDropdown}
              hoverable
              component="a"
            >
              <Image size="32x32">
                <Image.Content
                  className="profile-img"
                  src={user.profilePic || "/images/default-profile.png"}
                />
              </Image>
              <span className="dropdown-caret" />
            </Navbar.Item> */}
        {/* </Navbar.End> */}
        {/* </Navbar.Menu> ) : ( */}

        {showBurgerMenu && (
          <Item onClick={() => {
            setIsBurgerMenuVisible(!isBurgerMenuVisible);
            noScroll.on();
          }}
          >
            <Burger />
          </Item>
        )}

        <Navbar.Menu>
          <Navbar.End>
            <Navbar.Item to="/" component={Link}>
              <Item>Home</Item>
            </Navbar.Item>
            <LinkHash to="/#about">
              <Item>About Us</Item>
            </LinkHash>
            <Navbar.Item to="/faq" component={Link}>
              <Item>
                FAQs
              </Item>
            </Navbar.Item>
            <Navbar.Item to="/dashboard/search" component={Link}>
              <Item>
                {user.userType === 'Learner' ? 'Find a Tutor' : 'My Account'}
              </Item>
            </Navbar.Item>
            {!auth ? (
              <Navbar.Item to="/register" component={Link}>
                <Item signUp>Sign Up</Item>
              </Navbar.Item>
            ) : (
              <Navbar.Item to="/login" component={Link}>
                <Item signUp onClick={logout} onKeyPress={logout}>
                  Log Out
                </Item>
              </Navbar.Item>
            )}
          </Navbar.End>
        </Navbar.Menu>
        <UserDropdown open={open} closeDropdown={closeDropdown} />
      </NavbarStyled>

      {auth && isTabletOrMobile && (
        <MobileNav isTabletOrMobile={isTabletOrMobile}>
          <StyledList isTabletOrMobile={isTabletOrMobile}>
            <Link to="/home">
              <NavItem isTabletOrMobile={isTabletOrMobile} isSelected={pathname === '/home'}>
                <NavIcon>
                  <Home fill={pathname === '/home' ? '#fff' : '#c4c3c6'} />
                </NavIcon>
                {!isTabletOrMobile && 'Home'}
              </NavItem>
            </Link>
            <Link to="/dashboard/search">
              <NavItem isTabletOrMobile={isTabletOrMobile} isSelected={pathname === '/dashboard/search'}>
                <NavIcon>
                  <Search fill={pathname === '/dashboard/search' ? '#fff' : '#c4c3c6'} />
                </NavIcon>
                {!isTabletOrMobile && 'Tutor Search'}
              </NavItem>
            </Link>
            <Link to="/dashboard/requests">
              <NavItem isTabletOrMobile={isTabletOrMobile} isSelected={pathname === '/dashboard/requests'}>
                <NavIcon>
                  <Activity fill={pathname === '/dashboard/requests' ? '#fff' : '#c4c3c6'} />
                </NavIcon>
                {!isTabletOrMobile && 'Requests'}
              </NavItem>
            </Link>

            <Link to="/dashboard/settings">
              <NavItem isTabletOrMobile={isTabletOrMobile} isSelected={pathname === '/dashboard/settings'}>
                <NavIcon>
                  <Profile fill={pathname === '/dashboard/settings' ? '#fff' : '#c4c3c6'} />
                </NavIcon>
                {!isTabletOrMobile && 'Profile'}
              </NavItem>
            </Link>
          </StyledList>
        </MobileNav>
      )}

    </React.Fragment>
  );
}

Navigation.propTypes = {
  pathname: PropTypes.string.isRequired,
  isTabletOrMobile: PropTypes.bool,
};

const MobileNav = styled.div`
      ${({ isTabletOrMobile }) => isTabletOrMobile && `
      width: 100px;
      background: white;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      margin: 0;
      z-index: 3;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      padding-top: 20px;
      padding: 0;
      bottom: 0;
      width: 100vw;
      height: 70px;
      position: fixed;
      justify-content: center
      position: fixed;
      left: 0;
`};
      `;

const LinkHash = styled(HashLink)`
      margin-top: 16px;
      `;

const NavbarStyled = styled(Navbar)`
      ${({ isHome }) => (isHome ? 'position: fixed' : 'position: relative')};
      top: 58px;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 0;
      `;

const NavItem = styled.li`
      margin: 17px 0;
      font-family: Josefin Sans;
      font-weight: 500;
      color: #c4c3c6;
      font-size: 18px;
      line-height: 40px;
      cursor: pointer;
      ${({ isSelected }) => isSelected && 'color: #fff;'}
      ${({ isSelected }) => isSelected && `background: #363636;
      box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;`}
      padding: 5px 0px;
      font-weight: 700;
      padding-bottom: 10px;
      margin-right: 10px;
      border-radius: 10px;

      ${({ isTabletOrMobile }) => isTabletOrMobile && `
      margin: 0;
      padding: 0px 15px;
  `}
      `;

const NavIcon = styled.span`
      margin: 0 15px;
      @media screen and (max-width: 1024px) {
        margin - right: 10px;
      }

      svg {
        position: relative;
      top: 12px;
      }
      `;

const StyledList = styled.ul`
      margin-top: 100px;
      ${({ isTabletOrMobile }) => isTabletOrMobile && `
      display: flex;
      margin-top: 0;
      justify-content: space-evenly;
      width: 100%;
      align-items: center;
      padding: 0px 15px;
      height: 100%;
`}`;

const Item = styled.button`
      cursor: pointer;
      background: none;
      border: none;
      font-family: Josefin Sans;
      font-weight: 600;
      font-size: 16px;
      color: white;
      margin: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      ${(props) =>
    props.signUp && `width: 128px;
    height: 40px;
    background: #1AB7B7;
    border-radius: 40px;
    color: #fff;`};
      `;
