import request from 'superagent';
import { handleSuccess, handleError } from '_utils/api';

export const postRequest = info =>
  request.post('/api/requests')
    .send(info)
    .then(handleSuccess)
    .catch(handleError);

export const getRequests = () =>
  request.get('/api/requests')
    .then(handleSuccess)
    .catch(handleError);

// export const putToggleCompleteTodo = info =>
//   request.put('/api/todos/complete')
//     .send(info)
//     .then(handleSuccess)
//     .catch(handleError);

export const putRequest = info =>
  request.put('/api/requests')
    .send(info)
    .then(handleSuccess)
    .catch(handleError);

export const payRequest = (data) =>
  request.post('/api/requests/create-checkout-session')
    .send(data)
    .then(handleSuccess)
    .catch(handleError);

// export const deleteTodo = info =>
//   request.delete('/api/todos')
//     .send(info)
//     .then(handleSuccess)
//     .catch(handleError);
