import PropTypes from 'prop-types';
import React from 'react';

const Activity = ({ fill }) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 22"
    fill={fill || '#3C3C3C'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4168 5.52583V12.6667C17.4168 13.5375 16.7043 14.25 15.8335 14.25H4.75016L1.5835 17.4167V3.16667C1.5835 2.29583 2.296 1.58333 3.16683 1.58333H11.1627C11.1152 1.83667 11.0835 2.10583 11.0835 2.375C11.0835 2.64417 11.1152 2.91333 11.1627 3.16667H3.16683V12.6667H15.8335V6.25417C16.4193 6.13542 16.9577 5.87417 17.4168 5.52583ZM12.6668 2.375C12.6668 3.68917 13.7277 4.75 15.0418 4.75C16.356 4.75 17.4168 3.68917 17.4168 2.375C17.4168 1.06083 16.356 0 15.0418 0C13.7277 0 12.6668 1.06083 12.6668 2.375Z"
      fill={fill || '#3C3C3C'}
      fillOpacity="0.92"
    />
  </svg>
);

Activity.propTypes = {
  fill: PropTypes.string,
};

export default Activity;
