import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const LogoStyled = ({ dark, large }) => (
  <LogoWrapper large={!!large}>
    <BlackText dark={!!dark}>Tuition</BlackText>
    <BlueText>Lab</BlueText>
  </LogoWrapper>
);

LogoStyled.propTypes = {
  dark: PropTypes.bool,
  large: PropTypes.bool,
};

const LogoWrapper = styled.span`
  font-family: Josefin Sans;
  font-weight: 700;
  font-size: ${({ large }) => (large ? '40px' : '25px')};
  color: #fff;
`;

const BlackText = styled.span`
  color: ${({ dark }) => (dark ? '#363636' : '#fff')};
`;

const BlueText = styled.span`
  color: #1ab7b7;
`;

export default LogoStyled;
