import PropTypes from 'prop-types';
import React from 'react';

const Home = ({ fill }) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 18H11V16H13V18ZM15 14H9V20H15V14ZM19 9.3V4H16V6.6L12 3L2 12H5L12 5.69L19 12H22L19 9.3Z"
      fill={fill}
      fillOpacity="0.92"
    />
  </svg>
);

Home.propTypes = {
  fill: PropTypes.string,
};

export default Home;
