import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router';
import ReactNotification from 'react-notifications-component';
import { useDispatch } from 'react-redux';
import R from 'ramda';
import LeftNavigation from '_organisms/LeftNavigation/LeftNavigation';
import styled from 'styled-components';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { attemptGetUser } from '_thunks/user';

import WelcomePage from '_pages/WelcomePage';
import LoginPage from '_pages/LoginPage';
import RegisterPage from '_pages/RegisterPage';
// import HomePage from "_pages/HomePage";
// import TodoPage from "_pages/TodoPage";
// import SettingsPage from "_pages/SettingsPage";
// import LostPage from "_pages/LostPage";

import Navigation from '_organisms/Navigation';
import SoonPage from '_pages/SoonPage';
import SearchPage from '_pages/SearchPage';
import Footer from '_organisms/Footer';
import { useMediaQuery } from 'react-responsive';
import TutorProfilePage from '../../pages/TutorProfilePage';
import GeneralProfile from '../../organisms/GeneralProfile/GeneralProfile';
import RequestsPage from '../../pages/RequestsPage';
import FAQPage from '../../pages/FAQPage';

const STRIPE_PK = 'pk_test_51KsXZEHUlYPlbO4paHL0WPEPZCaJB8X40ZHmRpcV5ZoPrZqg5PCWXRcdj0IaSODN7nmiXtkmwOKFm8emsIBFi68q00StcPeJh4';
const stripePromise = loadStripe(STRIPE_PK);

export default function Main({ location }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  useEffect(() => {
    let subscribed = true;

    dispatch(attemptGetUser())
      .then(() => subscribed && setLoading(false))
      .catch(R.identity);

    return () => {
      subscribed = false;
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const isHome = (!(location.pathname).includes('/dashboard/'));

  return (
    !loading && (
      <React.Fragment>
        <ReactNotification />
        <Elements stripe={stripePromise || ''}>
          <MainWrapper isHome={isHome}>
            <NavigationWrapper>
              <Navigation pathname={location.pathname} isTabletOrMobile={isTabletOrMobile} />
            </NavigationWrapper>
            <LeftNavigation />
            <Switch>
              <Route exact path="/" component={WelcomePage} />
              <Route path="/login" component={LoginPage} />
              <Route path="/register" component={RegisterPage} />
              <Route path="/home" component={WelcomePage} />
              <Route path="/faq" component={FAQPage} />

              <DashboardWrapper isTabletOrMobile={isTabletOrMobile}>
                <Route path="/dashboard/search" component={SearchPage} />
                <Route path="/dashboard/profile/:id" component={TutorProfilePage} exact />
                <Route path="/dashboard/requests" component={RequestsPage} />
                <Route path="/dashboard/settings" component={GeneralProfile} />
              </DashboardWrapper>

              <Route path="*" component={SoonPage} />
            </Switch>
          </MainWrapper>
        </Elements>
        <Footer />
      </React.Fragment>
    )
  );
}

Main.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

const DashboardWrapper = styled.div`
padding: 0 20px;
${({ isTabletOrMobile }) => !isTabletOrMobile ? 'width: 55vw' : 'width: 100%'};
`;

const MainWrapper = styled.div`
display: flex;
flex-direction: ${(props) => (props.isHome ? 'column' : 'row')};
justify-content: center;
`;

const NavigationWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;

@media screen and(max-width: 767px) {
  display: none;
}
`;
