import PropTypes from 'prop-types';
import React from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import styled from 'styled-components';

export const TagInput = ({ list, setList, save, placeholderValue }) => {
  const handleDelete = i => {
    setList(list.filter((tag, index) => index !== i));
  };

  const handleAddition = tag => {
    setList([...list, tag]);
    save();
  };

  return (
    <Wrapper>
      <ReactTags
        tags={list}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        inputFieldPosition="top"
        autocomplete
        allowDragDrop={false}
        placeholder={placeholderValue}
      />
    </Wrapper>
  );
};

TagInput.propTypes = {
  setList: PropTypes.any.isRequired,
  list: PropTypes.any.isRequired,
  save: PropTypes.func,
  placeholderValue: PropTypes.string,
};

const Wrapper = styled.div`

.ReactTags__tagInputField {
    margin: 10px 10px 10px 0;
    transition: all 0.2s;
    width: 100%;
    border: none;
    outline: 1px solid #e2e2e2;
    border-radius: 5px;
    padding: 14px;
    background: #fff;
}

.ReactTags__remove {
    appearance: none;
    border: none;
    background: none;
    color: white;
    margin-bottom: 4px;
    font-size: 17px;
}

.button {
    width: 120px;
}

.tag-wrapper {
    padding: 4px;
    height: 39px;
    cursor: pointer;
    background: #1ab7b7;
    border-radius: 40px;
    font-family: Josefin Sans;
    color: #ffffff;
    margin: 10px 10px 10px 0;

    opacity: 1;
    cursor: move;
    min-width: 90px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
`;
