import request from 'superagent';
import { handleSuccess, handleError } from '_utils/api';

export const postCheckUsername = (username) =>
  request
    .post('/api/users/checkusername')
    .send({ username })
    .then(handleSuccess)
    .catch(handleError);

export const getUserList = () =>
  request
    .get('/api/users/tutorlist')
    .then(handleSuccess)
    .catch(handleError);

export const searchTutors = (query) =>
  request
    .post('/api/users/search')
    .send({ query })
    .then(handleSuccess)
    .catch(handleError);

export const getTutorById = (id) =>
  request
    .get(`/api/user/profile/${id}`)
    .send(id)
    .then(handleSuccess)
    .catch(handleError);
