import React from 'react';

// import Footer from 'react-bulma-companion/lib/Footer';
import Container from 'react-bulma-companion/lib/Container';
import Content from 'react-bulma-companion/lib/Content';
import styled from 'styled-components';

export const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <FooterStyled>
      <Container>
        <Content className="has-text-centered">
          <p>
            Contact us at
            {' '}
            <a href="mailto:support@tuition-lab.com" target="_blank" rel="noreferrer">support@tuition-lab.com</a>
            <br />
            {`Copyright Ⓒ ${year} TuitionLab. All Rights Reserved.`}
          </p>

        </Content>
      </Container>
    </FooterStyled>
  );
};

const FooterStyled = styled.footer`
font-family: Lato;
padding: 100px;
min-height: 40px;
border-top: 2px solid #f7f7f7;
`;

export default Footer;
