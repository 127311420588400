import React from 'react';
import ComingSoon from '_templates/ComingSoon';
import styled from 'styled-components';

export default function LostPage() {
  return (
    <SectionWrapper className="soon-page page">
      <ComingSoon />
    </SectionWrapper>
  );
}

const SectionWrapper = styled.section`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  min-height: 70vh;
  justify-content: flex-start;
  margin: 30px;

  @media screen and (min-width: 757px) {
    justify-content: space-evenly;
  }

  ${(props) => props.short && 'min-height: 50vh;'}
  ${(props) => props.centered && 'justify-content: center;'}
  ${(props) =>
    props.blue && 'background-color: rgba(26,183,183, 0.1); margin: 0px;'}
`;
