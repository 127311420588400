import React, { useState } from 'react';
import styled from 'styled-components';
import Navbar from 'react-bulma-companion/lib/Navbar';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import R from 'ramda';
import { attemptLogout } from '_thunks/auth';
import PropTypes from 'prop-types';

const noScroll = require('no-scroll');

const BurgerMenuPage = ({ setIsBurgerMenuVisible }) => {
  const { user } = useSelector(R.pick(['user']));
  const dispatch = useDispatch();
  const [auth] = useState(!R.isEmpty(user));

  const logout = () => {
    dispatch(attemptLogout()).catch(R.identity);
  };

  const handleLinkClick = () => {
    setIsBurgerMenuVisible(false);
    noScroll.off();
  };

  return (
    <Wrapper>
      <CloseIcon onClick={() => {
        noScroll.off();
        setIsBurgerMenuVisible(false);
      }}
      >
        ×
      </CloseIcon>
      <LinksContainer>
        <Navbar.Item to="/" component={Link} onClick={() => handleLinkClick()}>
          <Item>Home</Item>
        </Navbar.Item>
        <Navbar.Item to="/faq" component={Link} onClick={() => handleLinkClick()}>
          <Item>
            FAQs
          </Item>
        </Navbar.Item>
        <Navbar.Item to="/dashboard/search" component={Link} onClick={() => handleLinkClick()}>
          <Item>
            {user.userType === 'Learner' ? 'Find a Tutor' : 'My Account'}
          </Item>
        </Navbar.Item>
        {!auth ? (
          <Navbar.Item to="/register" component={Link} onClick={() => handleLinkClick()}>
            <Item signUp>Sign Up</Item>
          </Navbar.Item>
        ) : (
          <Navbar.Item to="/login" component={Link} onClick={() => handleLinkClick()}>
            <Item signUp onClick={logout} onKeyPress={logout}>
              Log Out
            </Item>
          </Navbar.Item>

        )}
      </LinksContainer>
    </Wrapper>
  );
};

export default BurgerMenuPage;

BurgerMenuPage.propTypes = {
  setIsBurgerMenuVisible: PropTypes.func,
};

const CloseIcon = styled.a`
color: black;
position: absolute;
right: 0;
padding: 0px;
top: 0;
font-size: 34px;
margin-right: 12px;
`;

const Wrapper = styled.section`
  position: fixed;
  z-index: 5;
  height: 100vh;
  width: 100vw;
  background: white;
  display: flex;
  justify-content: center;
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 50vh;
  align-items: center;
  justify-content: space-evenly;
`;

const Item = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  font-family: Josefin Sans;
  font-weight: 600;
  font-size: 16px;
  color: black;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.signUp && `width: 128px;
  height: 40px;
  background: #1AB7B7;
  border-radius: 40px;
  color: #fff;`};
      `;
