import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import R from 'ramda';
import { push } from 'connected-react-router';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSync } from '@fortawesome/free-solid-svg-icons/faSync';
import Control from 'react-bulma-companion/lib/Control';
import { Checkbox } from 'react-bulma-companion';
import Help from 'react-bulma-companion/lib/Help';
import { LandingSubheading, EditProfileHeading } from '_atoms/Title/Titles';
import { LandingButton } from '_atoms/Buttons/Buttons';
import { EditProfileSubtitle } from '_atoms/Body/BodyTexts';
import styled from 'styled-components';
import { validateName } from '_utils/validation';
import { attemptUpdateUser } from '_thunks/user';
import Toggle from '_atoms/Toggle/Toggle';
import { TagInput } from '../../molecules/TagInput/TagInput';
import DefaultPic from '../../../assets/images/default-profile.png';

export default function GeneralProfile() {
  const dispatch = useDispatch();
  const { user } = useSelector(R.pick(['user']));

  const [firstName, setFirstName] = useState(user.firstName || '');
  const [lastName, setLastName] = useState(user.lastName || '');
  const [location, setLocation] = useState(user.location || '');
  const [visibility, setVisibility] = useState(user.visibility || 'Hidden');
  const [headline, setHeadline] = useState(user.headline || '');
  const [bio, setBio] = useState(user.bio || '');
  const [profilePic, setProfilePic] = useState(user.profilePic || '');
  const [typeTags, setTypeTags] = useState(user.typeTags || []);
  const [skills, setSkills] = useState(user.skills || []);
  const [hourlyRate, setHourlyRate] = useState(user.hourlyRate || 0);
  const [languages, setLanguages] = useState(user.languages || []);
  const [firstNameEdited, setFirstNameEdited] = useState(false);
  const [lastNameEdited, setLastNameEdited] = useState(false);
  const [headlineEdited, setHeadlineEdited] = useState(false);
  const [bioEdited, setBioEdited] = useState(false);
  const [visibilityEdited, setVisibilityEdited] = useState(false);
  const [typeTagsEdited, setTypeTagsEdited] = useState(false);
  const [locationEdited, setLocationEdited] = useState(false);
  const [profilePicEdited, setProfilePicEdited] = useState(false);
  const [skillsListEdited, setSkillsListEdited] = useState(false);
  const [languagesEdited, setLanguagesEdited] = useState(false);
  const [hourlyRateEdited, setHourlyRateEdited] = useState(false);

  useEffect(() => {
    if (R.isEmpty(user)) {
      dispatch(push('/login'));
    }
  }, []);

  const resetState = () => {
    setFirstName(user.firstName || '');
    setLastName(user.lastName || '');
    setBio(user.bio || '');
    setProfilePic(user.profilePic || '');
    setFirstNameEdited(false);
    setLastNameEdited(false);
    setBioEdited(false);
    setProfilePicEdited(false);
    setHeadline(user.headline || '');
    setLocation(user.location || '');
    setSkills(user.skills || []);
    setLanguages(user.languages || []);
    setHourlyRate(user.hourlyRate || 0);
  };

  useEffect(() => {
    resetState();
  }, [user.firstName, user.lastName, user.bio, user.profilePic]);

  const updateFirstName = e => {
    if (validateName(e.target.value)) {
      setFirstName(e.target.value);
      setFirstNameEdited(true);
    }
  };

  const updateLastName = e => {
    if (validateName(e.target.value)) {
      setLastName(e.target.value);
      setLastNameEdited(true);
    }
  };

  const updateHeadline = e => {
    setHeadline(e.target.value);
    setHeadlineEdited(true);
  };

  const updateBio = e => {
    setBio(e.target.value);
    setBioEdited(true);
  };

  const updateLocation = e => {
    setLocation(e.target.value);
    setLocationEdited(true);
  };

  const updateProfilePic = e => {
    setProfilePic(e.target.value);
    setProfilePicEdited(true);
  };

  const updateSkillsList = list => {
    setSkills(list);
    setSkillsListEdited(true);
  };

  const updateLanguages = list => {
    setLanguages(list);
    setLanguagesEdited(true);
  };

  const updateHourlyRate = e => {
    setHourlyRate(e.target.value);
    setHourlyRateEdited(true);
  };

  const handleToggle = (name) => {
    const index = typeTags.indexOf(name);
    const newTypeTags = typeTags.filter(item => item !== name);

    if (index !== -1) {
      setTypeTags(newTypeTags);
    } else {
      setTypeTags([...typeTags, name]);
    }
    setTypeTagsEdited(true);
  };

  // const refresh = () => dispatch(attemptGetUser())
  //   .then(resetState)
  //   .catch(R.identity);

  const save = () => {
    const updatedUser = {};

    if (firstNameEdited) { updatedUser.first_name = firstName; }
    if (lastNameEdited) { updatedUser.last_name = lastName; }
    if (profilePicEdited) { updatedUser.profile_pic = profilePic; }
    if (bioEdited) { updatedUser.bio = bio; }
    if (headlineEdited) { updatedUser.headline = headline; }
    if (locationEdited) { updatedUser.location = location; }
    if (typeTagsEdited) { updatedUser.type_tags = typeTags; }
    if (visibilityEdited) { updatedUser.visibility = visibility; }
    if (skillsListEdited) { updatedUser.skills = skills; }
    if (languagesEdited) { updatedUser.languages = languages; }
    if (hourlyRateEdited) { updatedUser.hourly_rate = hourlyRate; }

    if (!R.isEmpty(updatedUser)) {
      dispatch(attemptUpdateUser(updatedUser))
        .catch(R.identity);
    }
  };

  // const charactersRemaining = 240 - bio.length;
  // eslint-disable-next-line max-len
  const edited = firstNameEdited || lastNameEdited || bioEdited || profilePicEdited || headlineEdited || locationEdited || typeTagsEdited || visibilityEdited || skillsListEdited || languagesEdited || hourlyRateEdited;

  return (
    <ProfileArea className="general-profile">
      {/* <Icon size="medium" className="is-pulled-right" onClick={refresh} onKeyPress={refresh}>
        <FontAwesomeIcon icon={faSync} size="lg" />
      </Icon> */}
      <LandingSubheading>My Profile</LandingSubheading>
      <PreviewContainer>
        <PreviewAvatar src={profilePic || DefaultPic} alt="profile photo" />
        <PreviewText>
          <EditProfileHeading>{firstName}</EditProfileHeading>
          <EditProfileSubtitle>{location || 'Your Location'}</EditProfileSubtitle>
          <EditProfileSubtitle>{user.usernameCase}</EditProfileSubtitle>
        </PreviewText>
      </PreviewContainer>
      <InputWrapper>
        <InputComponent>
          <label htmlFor="first-name" className="Label">
            First Name
          </label>
          <Control>
            <StyledInput
              id="first-name"
              placeholder="First Name"
              value={firstName}
              onChange={updateFirstName}
            />
          </Control>
        </InputComponent>

        <InputComponent>
          <label htmlFor="last-name">
            Last Name
          </label>
          <Control>
            <StyledInput
              id="last-name"
              placeholder="Last Name"
              value={lastName}
              onChange={updateLastName}
            />
          </Control>
        </InputComponent>

        <InputComponent>
          <label htmlFor="headline">
            Headline
          </label>
          <Control>
            <StyledInput
              id="headline"
              placeholder="Headline"
              value={headline}
              onChange={updateHeadline}
            />
          </Control>
        </InputComponent>

        <InputComponent>
          <label htmlFor="location">
            Location
          </label>
          <Control>
            <StyledInput
              id="location"
              placeholder="Location"
              value={location}
              onChange={updateLocation}
            />
          </Control>
        </InputComponent>

        <InputComponent>
          <label htmlFor="bio">
            Bio
          </label>
          <Control>
            <StyledTextarea
              id="bio"
              placeholder="Tell us about yourself."
              value={bio}
              // maxLength={240}
              onChange={updateBio}
            />
          </Control>

          <Help>
            {/* {`Characters remaining: ${charactersRemaining}`} */}
          </Help>
        </InputComponent>

        {user.userType === 'Tutor'
          && (
            <React.Fragment>
              <InputComponent>
                <label htmlFor="profile-pic-url" className="Label">
                  Profile Picture
                </label>
                <Control />
                <StyledInput
                  id="profile-pic-url"
                  placeholder="Picture URL"
                  value={profilePic}
                  onChange={updateProfilePic}
                />
              </InputComponent>

              <InputComponent>
                <label htmlFor="skills">
                  Subjects Taught
                </label>
                <TagInput list={skills} setList={updateSkillsList} save={save} placeholderValue="Enter Subject & Level e.g. Physics A-Level, Maths KS3, etc." />
              </InputComponent>

              <InputComponent>
                <label htmlFor="languages">
                  Languages Spoken
                </label>
                <TagInput list={languages} setList={updateLanguages} save={save} placeholderValue="Enter language and level e.g. Fluent French" />
              </InputComponent>

              <InputComponent>
                <label htmlFor="userType">
                  Tutor Locations
                </label>
                <Checkbox>
                  <Toggle name="Online" selected={typeTags.includes('Online')} onClick={() => handleToggle('Online')} />
                  <Toggle name="In Person" selected={typeTags.includes('In Person')} onClick={() => handleToggle('In Person')} />
                </Checkbox>
              </InputComponent>

              <InputComponent>
                <label htmlFor="visibility">
                  Profile Visibility
                </label>
                <Checkbox>
                  <Toggle name="Hidden" selected={visibility === ('Hidden')} onClick={() => { setVisibility('Hidden'); setVisibilityEdited(true); }} />
                  <Toggle name="Visible" selected={visibility === ('Visible')} onClick={() => { setVisibility('Visible'); setVisibilityEdited(true); }} />
                </Checkbox>
              </InputComponent>

              <InputComponent>
                <label htmlFor="hourlyRate">
                  Hourly Rate
                </label>
                <Control>
                  <StyledInput
                    id="hourlyRate"
                    placeholder={15}
                    value={hourlyRate}
                    type="number"
                    onChange={updateHourlyRate}
                    min="1"
                  />
                </Control>
              </InputComponent>
            </React.Fragment>
          )}

      </InputWrapper>
      <ButtonWrapper>
        <LandingButton onClick={save} disabled={!edited}>
          Save
        </LandingButton>
      </ButtonWrapper>
    </ProfileArea>
  );
}

const ButtonWrapper = styled.div`
margin: 20px 0;
`;

const InputComponent = styled.div`
display: flex;
flex-direction: column;
margin: 10px;
min-height: 70px;
`;

const InputWrapper = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
margin: 50px 0;
align-items: start;
justify-content: center;

label {
  margin: 5px 5px 5px 0;
}
`;
const StyledTextarea = styled.textarea`
transition: all 0.2s;
width: 100%;
border: none;
outline: 1px solid #e2e2e2;
border-radius: 5px;
padding: 14px;
background: #fff;
resize: vertical;


&:hover {
  background: #fff;
}

&:focus {
  outline: 2px solid #1ab6b7;
}
`;

const StyledInput = styled.input`
transition: all 0.2s;
width: 100%;
border: none;
outline: 1px solid #e2e2e2;
border-radius: 5px;
padding: 14px;
background: #fff;
padding: 20px;

&:hover {
  background: #fff;
}

&:focus {
  outline: 2px solid #1ab6b7;
}
`;

const PreviewAvatar = styled.img`
width: 120px;
height: 120px;
border-radius: 50%;
box-shadow: -1px 1px 14px 0px rgb(0 0 0 / 25%);
`;

const PreviewContainer = styled.section`
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
flex-wrap: wrap;
margin: 20px 0;
`;

const PreviewText = styled.section`
display: flex;
justify-content: center;
flex-direction: column;
text-align: left;
margin: 20px;
`;

const ProfileArea = styled.div`
flex: 1;
margin-top: 50px;
`;
