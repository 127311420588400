import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import R from 'ramda';
import { Link } from 'react-router-dom';
import Section from 'react-bulma-companion/lib/Section';
import styled from 'styled-components';
import Image from '../../../assets/images/blobs.png';
import AboutImage from '../../../assets/images/aboutblobs.png';
import ListItem from '../../../assets/images/ListItem.png';
import Alarm from '../../../assets/images/alarm.png';
import Account from '../../../assets/images/supervisor_account.png';
import Reg from '../../../assets/images/how_to_reg.png';
import ZigZag from '../../../assets/images/zigzag.png';
import {
  LandingHeading,
  LandingMiniText,
  LandingText,
} from '../../atoms/Title/Titles';
import { LandingButton } from '../../atoms/Buttons/Buttons';

export default function WelcomePage() {
  const dispatch = useDispatch();
  const { user } = useSelector(R.pick(['user']));
  const [auth] = useState(!R.isEmpty(user));

  useEffect(() => {
    if (!R.isEmpty(user)) {
      dispatch(push('/home'));
    }
  }, []);

  return (
    <div className="welcome-page page">
      <SectionWrapper className="p-0">
        <LandingContentWrapper>
          {!auth ? (
            <React.Fragment>
              <LandingMiniText>TEACH & EARN</LandingMiniText>
              <LandingHeading>Early tutors’ sign up offer</LandingHeading>
              <LandingText>
                Create your account before 31st May to unlock your first
                <BoldText> free </BoldText>
                tutee connection, worth £25!
              </LandingText>
              <ButtonWrapper>
                <Link to="/register">
                  <LandingButton>Get Started</LandingButton>
                </Link>
                <Link to="#about">
                  <LandingButton outline>Learn More</LandingButton>
                </Link>
              </ButtonWrapper>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <LandingHeading>Thanks for signing up!</LandingHeading>
              <LandingText>
                We&apos;ll let you know when we&apos;ve officially launched. Keep an eye
                on your inbox!
              </LandingText>
            </React.Fragment>
          )}
        </LandingContentWrapper>
        <LandingImage
          src={Image}
          alt="landing-image"
        />
      </SectionWrapper>
      <SectionWrapper>
        <LandingImage src={AboutImage} alt="landing-image" />
        <LandingContentWrapper>
          <div id="about">
            <LandingHeading>About Us</LandingHeading>
            <LandingText>
              Our website provides the platform for tutors advertise their
              profile and tutoring services (subjects, tutoring/teaching
              experience, qualifications, etc.) to prospective tutees.
            </LandingText>
            <ul>
              <CustomListItem>
                <img src={ListItem} alt="arrow bullet point" />
                <LandingText>
                  Established private tutors (freelancers, self-employed,
                  ex-teachers, educators, etc.) – typically already using
                  tutoring websites and want more exposure
                  {' '}
                </LandingText>
              </CustomListItem>
              <CustomListItem>
                <img src={ListItem} alt="arrow bullet point" />
                <LandingText>
                  Unestablished private tutors (e.g. University students) –
                  typically new entrants creating additional sources of personal
                  income
                </LandingText>
              </CustomListItem>
            </ul>
          </div>
        </LandingContentWrapper>
      </SectionWrapper>
      <SectionWrapper centered="true" blue="true">
        <LandingContentWrapper wide>
          <LandingHeading centered="true">how it works</LandingHeading>
          <ZigzagImg src={ZigZag} />
          <ZigzagImg right src={ZigZag} />

          <LandingText centered="true">
            Create your account before 31st May to unlock your first free
            tutee connection, worth £25!
          </LandingText>
          <GridItems>
            <GridItem>
              <GridItemImg src={Alarm} />
              Easy-to-use and FREE sign up process for:
              Tutors to advertise their services
              Tutees to find their tutors
            </GridItem>
            <GridItem>
              <GridItemImg src={Reg} />
              Once you find a tutor, the tutee pays a one-off
              fee that guarantees them one free hour of tuition per
              tutor – it’s a great deal to trial a new tutor!*
            </GridItem>
            <GridItem>
              <GridItemImg src={Account} />
              The platform is completely FREE for tutors to
              advertise and connect with new learners**
            </GridItem>
          </GridItems>
          *In the unlikely and unfortunate case a tutor does not deliver the promised
          first free hour of tuition, we will provide the tutee with a full refund. This
          is our Money Back Guarantee promise. **In exchange for zero cost to use the
          TuitionLab® platform, our tutors agree to the Terms of Service:
          To deliver the first hour of tuition for free
          To deliver high-quality tuition
        </LandingContentWrapper>
      </SectionWrapper>

      {!auth && (
        <SectionWrapper centered="true" short="true" wide="true">
          <BigButton>
            <BigButtonContent>
              <BigButtonText>
                <ButtonHeading>Let&apos;s get started!</ButtonHeading>
                <BigButtonText>
                  Registering with TuitionLab as a tutor only takes a few minutes.
                </BigButtonText>
              </BigButtonText>
              <Link to="/register">
                <BigButtonButton>Sign up</BigButtonButton>
              </Link>
            </BigButtonContent>
          </BigButton>
        </SectionWrapper>
      )}
    </div>
  );
}

const ZigzagImg = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 2rem;

  ${(props) => props.right && 'left: unset; right: 0; top:0'}
`;

const BigButton = styled.section`
  background: #3c3c3c;
  box-shadow: 5px 4px 13px 5px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  width: 100%;
  height: 300px;

  @media screen and (max-width: 757px) {
    background: none;
    box-shadow: none;
  }
`;

const BigButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  flex-wrap: wrap;
  color: #ffffff;

  @media screen and (max-width: 757px) {
    justify-content: flex-start;
    color: #3c3c3c;
  }
`;

const BigButtonButton = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  width: 200px;
  height: 74px;
  background: #1ab7b7;
  box-shadow: -1px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  font-weight: 900;
  font-family: Josefin Sans;
  font-size: 20px;
  line-height: 41px;
  text-align: center;
  color: #ffffff;

  @media screen and (max-width: 757px) {
    background: none;
    box-shadow: none;
    width: 200px;
    height: 50px;
    color: #1ab7b7;
    border: 1px solid #1ab7b7;
    border-radius: 25px;
  }
`;

const BigButtonText = styled.div`
  font-family: Lato;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  font-size: 20px;
  line-height: 28px;
  margin: 10px 0;

  @media screen and (max-width: 757px) {
    font-size: 15px;
    margin: 0;
  }
`;

const ButtonHeading = styled.div`
font-family: Josefin Sans;
font-size: 50px;
  letter-spacing: -0.045em;
  margin: 20px 0;

  @media screen and (max-width: 757px) {
    font-size: 30px;
  }
`;

const SectionWrapper = styled(Section)`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  min-height: 70vh;
  justify-content: flex-start;
  margin: 30px;

  @media screen and (min-width: 757px) {
    justify-content: space-evenly;
  }

  @media screen and (max-width: 757px) {
    justify-content: space-evenly;
    flex-wrap: wrap-reverse;
    min-height: 50vh;
  }

  ${(props) => props.short && 'min-height: 50vh;'}
  ${(props) => props.centered && 'justify-content: center;'}
  ${(props) =>
    props.blue && 'background-color: rgba(26,183,183, 0.1); margin: 0px;'}
`;

const CustomListItem = styled.li`
  margin-left: 50px;
  padding: 30px 0;
  position: relative;

  img {
    position: absolute;
    left: -50px;
  }
`;

const GridItem = styled.div`
  display: flex;
  flex-direction: column;

  text-align: left;
  font-family: Lato;
  font-size: 18px;

  letter-spacing: -0.005em;
  margin: 30px;
`;

const GridItemImg = styled.img`
  width: 30px;
  margin: 10px 0;
`;

const GridItems = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5rem 0;

  @media screen and (max-width: 757px) {
    flex-wrap: wrap;
  }
`;

const BoldText = styled.span`
  font-weight: 700 !important;
`;

const LandingImage = styled.img`
  max-width: 40vw;
  min-width: 300px;
`;

const LandingContentWrapper = styled.div`
  @media screen and (max-width: 757px) {
    width: 90%;
  }

  @media screen and (min-width: 757px) {
    max-width: 30vw;
    ${(props) => props.wide && 'max-width: 50vw;'}
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0;
`;
