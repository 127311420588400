import styled from 'styled-components';

export const LandingButton = styled.button`
  width: 130px;
  height: 40px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s;
  margin-right: 10px;
  background: #1ab7b7;
  border-radius: 40px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  margin-right: 1rem;
  cursor: pointer;
  font-family: Josefin Sans;

  &:hover {
    box-shadow: -1px 4px 10px rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    background: rgba(26,183,183, 0.4);
  }

  &:disabled:hover {
    box-shadow: none;
  }

  ${(props) =>
    props.outline
    && `background: #fff;
    border: 1px solid #1AB7B7;
    color: #1ab7b7;`}
    
    ${(props) => props.wide && 'width: 200px;'}
    ;
`;

export const SkillButton = styled.button`
  width: 60px;
  height: 20px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s;
  margin-right: 10px;
  background: #1ab7b7;
  border-radius: 40px;
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  margin-right: 1rem;
  cursor: pointer;

  &:hover {
    box-shadow: -1px 4px 10px rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    background: rgba(26,183,183, 0.4);
  }

  &:disabled:hover {
    box-shadow: none;
  }

  ${(props) =>
    props.outline
    && `background: #fff;
    border: 1px solid #1AB7B7;
    color: #1ab7b7;`}
    
    ${(props) => props.wide && 'width: 200px;'}
    ;
`;
