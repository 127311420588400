import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const parse = require('html-react-parser');

const QAComponent = ({ faq }) => {
  const [isHidden, setIsHidden] = useState(true);

  return (
    <React.Fragment>
      <Question onClick={() => setIsHidden(!isHidden)}>
        {faq.question}
      </Question>
      <Answer hidden={isHidden}>{parse(faq.answer)}</Answer>
    </React.Fragment>
  );
};

QAComponent.propTypes = {
  faq: PropTypes.object,
};

export default QAComponent;

const Question = styled.h2`
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  text-align: left;
  font-weight: 300;
  ${(props) => props.centered && 'text-align: center;'}
  cursor: pointer;
`;

const Answer = styled.h2`
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-align: left;
  margin: 10px 0;
  ${(props) => props.centered && 'text-align: center;'}
  ${(props) => props.hidden && 'display: none;'}
`;
