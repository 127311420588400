import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import R from 'ramda';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';

import Field from 'react-bulma-companion/lib/Field';
import Control from 'react-bulma-companion/lib/Control';
import Icon from 'react-bulma-companion/lib/Icon';
import Input from 'react-bulma-companion/lib/Input';
import Help from 'react-bulma-companion/lib/Help';
import Checkbox from 'react-bulma-companion/lib/Checkbox';

import useKeyPress from '_hooks/useKeyPress';
import { postCheckUsername } from '_api/users';
import { validateUsername, validatePassword } from '_utils/validation';
import { attemptRegister } from '_thunks/auth';
import styled from 'styled-components';

import {
  LandingHeading,
  LandingText,
  LandingMiniText,
} from '../../atoms/Title/Titles';

import { LandingButton } from '../../atoms/Buttons/Buttons';

export default function Register() {
  const dispatch = useDispatch();

  const [username, setUsername] = useState('');
  const [usernameMessage, setUsernameMessage] = useState('');
  const [password, setPassword] = useState('');
  const [userType, setUserType] = useState('');
  const [email, setEmail] = useState('');
  const [passwordMessage, setPasswordMessage] = useState('');
  const [usernameAvailable, setUsernameAvailable] = useState(false);
  // const [emailAvailable, setEmailAvailable] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [gdprChecked, setGdprChecked] = useState(false);

  const checkPassword = (newUsername, newPassword) => {
    const { valid, message } = validatePassword(newUsername, newPassword);

    setPasswordValid(valid);
    setPasswordMessage(message);
  };

  const checkUsername = (newUsername) => {
    const { valid, message } = validateUsername(newUsername);

    if (valid) {
      setUsernameMessage('Checking username...');
      setUsernameAvailable(false);

      postCheckUsername(newUsername)
        .then((res) => {
          setUsernameAvailable(res.available);
          setUsernameMessage(res.message);
        })
        .catch(R.identity);
    } else {
      setUsernameAvailable(valid);
      setUsernameMessage(message);
    }
  };

  const updateUsername = (newUserName) => {
    setUsername(newUserName);
    checkPassword(newUserName, password);
  };

  const handleUsernameChange = (e) => {
    updateUsername(e.target.value);
    checkUsername(e.target.value);
  };

  // const checkEmail = (newEmail) => {
  //   const { valid, message } = validateUsername(newEmail);

  //   if (valid) {
  //     // setEmailMessage("Checking username...");
  //     setEmailAvailable(false);

  //     postCheckEmail(newEmail)
  //       .then((res) => {
  //         setEmailAvailable(res.available);
  //         // setUsernameMessage(res.message);
  //       })
  //       .catch(R.identity);
  //   } else {
  //     setEmailAvailable(valid);
  //     // setEmailMessage(message);
  //   }
  // };

  const updateEmail = (newEmail) => {
    setEmail(newEmail);
    // checkPassword(newUserName, password);
  };

  const handleEmailChange = (e) => {
    updateEmail(e.target.value);
    // checkUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    checkPassword(username, e.target.value);
  };

  const register = () => {
    if (usernameAvailable && passwordValid && gdprChecked && userType !== '') {
      const newUser = {
        username,
        password,
        userType,
      };

      dispatch(attemptRegister(newUser)).catch(R.identity);
    }
  };

  useKeyPress('Enter', register);

  return (
    <Wrapper>
      <Content>
        <HeadingWrapper>
          <LandingHeading>Create an Account</LandingHeading>
          <LandingText>Get notified when we launch.</LandingText>
          <LandingMiniText>
            Already a member?&nbsp;
            <Link to="/login">Login here.</Link>
          </LandingMiniText>
        </HeadingWrapper>
        <div>
          <StyledField>
            <Control iconsRight>
              <Input
                id="username"
                placeholder="Email"
                color={
                  username
                    ? usernameAvailable
                      ? 'success'
                      : 'danger'
                    : undefined
                }
                value={username}
                onChange={handleUsernameChange}
              />
              {username && (
                <Icon
                  size="small"
                  align="right"
                  color={usernameAvailable ? 'success' : 'danger'}
                >
                  <FontAwesomeIcon
                    icon={usernameAvailable ? faCheck : faExclamationTriangle}
                  />
                </Icon>
              )}
            </Control>
            {username && (
              <Help color={usernameAvailable ? 'success' : 'danger'}>
                {usernameMessage}
              </Help>
            )}
          </StyledField>
          {/* <StyledField> */}
          {/* <Control iconsRight>
              <Input
                id="email"
                placeholder="Email"
                color={
                  email ? (emailAvailable ? "success" : "danger") : undefined
                }
                value={email}
                onChange={handleEmailChange}
              /> */}
          {/* {email && (
                <Icon
                  size="small"
                  align="right"
                  color={emailAvailable ? "success" : "danger"}
                >
                  <FontAwesomeIcon
                    icon={usernameAvailable ? faCheck : faExclamationTriangle}
                  />
                </Icon>
              )}
            </Control> */}
          {/* {username && (
            <Help color={usernameAvailable ? 'success' : 'danger'}>
              {usernameMessage}
            </Help>
             )} */}
          {/* </StyledField> */}
          <StyledField>
            <Control iconsRight>
              <Input
                id="password"
                placeholder="Password"
                type="password"
                color={
                  password ? (passwordValid ? 'success' : 'danger') : undefined
                }
                value={password}
                onChange={handlePasswordChange}
              />
              {password && (
                <Icon
                  size="small"
                  align="right"
                  color={passwordValid ? 'success' : 'danger'}
                >
                  <FontAwesomeIcon
                    icon={passwordValid ? faCheck : faExclamationTriangle}
                  />
                </Icon>
              )}
            </Control>
            {password && (
              <Help color={passwordValid ? 'success' : 'danger'}>
                {passwordMessage}
              </Help>
            )}
          </StyledField>
          <StyledCheckbox>
            <IAmText> I am a...</IAmText>
            <RadioWrapper>
              <RadioInput
                onChange={() => setUserType('Learner')}
                type="radio"
                name="usertype"
                value="Learner"
              />
              <LandingText>Learner</LandingText>
            </RadioWrapper>
            <RadioWrapper>
              <RadioInput
                onChange={() => setUserType('Tutor')}
                type="radio"
                name="usertype"
                value="Tutor"
              />
              <LandingText>Tutor</LandingText>
            </RadioWrapper>
          </StyledCheckbox>
        </div>
        <StyledCheckboxNoWrap>
          <input
            type="checkbox"
            onChange={() => setGdprChecked(!gdprChecked)}
            checked={gdprChecked}
            required
          />
          By checking this box, I acknoweldge and consent to TuitionLab Ltd to
          store my email and contact me. Your data will only be used for the
          purpose it was collected for and which you consented to. In line with
          GDPR regulations, your data will only be processed for this purpose,
          and will not be used for anything else.
        </StyledCheckboxNoWrap>
        <LandingButton
          wide
          onClick={register}
          disabled={
            !passwordValid
            || !usernameAvailable
            || !gdprChecked
            || userType === ''
          }
        >
          Create Account
        </LandingButton>
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const IAmText = styled.span`
  font-size: 20px;
  margin-right: 10px;

  @media screen and (max-width: 757px) {
    width: 100%;
  }

`;

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 5px;
`;

const RadioInput = styled.input`
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #1ab7b7;
  border-radius: 50%;
  background-clip: content-box;
  padding: 3px;
  margin-right: 10px;

  &:checked {
    background-color: #1ab7b7;
  }

  label {
    margin: 10px 0px;
  }
`;

const Content = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 10vh;

  @media screen and (min-width: 767px) {
    max-width: 40vw;
  }
`;

const StyledCheckboxNoWrap = styled(Checkbox)`
  font-size: 10px;
  display: flex;
  align-items: center;
  text-align: justify;
  margin-bottom: 20px;

  input[type="checkbox"] {
    margin-right: 10px;

    @media screen and (max-width: 757px) {
      transform: scale(1.5);
      margin-right: 20px;
      margin-bottom: 10px;
    }
  }
`;

const StyledCheckbox = styled(Checkbox)`
  font-size: 10px;
  display: flex;
  align-items: center;
  text-align: justify;
  margin-bottom: 20px;
  flex-wrap: wrap;

  input[type="checkbox"] {
    margin-right: 10px;

    @media screen and (max-width: 757px) {
      transform: scale(1.5);
      margin-right: 20px;
      margin-bottom: 10px;
    }
  }
`;

const StyledField = styled(Field)`
  .animation label {
    transform: translateY(-55px);
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 600;
  }

  input {
    padding: 2rem;
    font-family: Josefin Sans;
    font-weight: 300;
    font-size: 2rem;
    border: none;
    box-shadow: none;
    border-bottom: 1px solid #ccc;

    &:focus {
      outline: none;
      border-bottom: 1px solid #28a2a2;
    }

    @media screen and (max-width: 676px) {
      font-size: 1rem;
    }
  }
`;

const HeadingWrapper = styled.div`
  margin-bottom: 3rem;
`;
