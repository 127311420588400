import React from 'react';
import styled from 'styled-components';

export const EmptyResults = () => (
  <Wrapper>
    <LandingText>Nothing here yet.</LandingText>
  </Wrapper>

);

const Wrapper = styled.div`
`;

const LandingText = styled.h2`
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 23px;
  line-height: 30px;
  text-align: left;
  letter-spacing: -0.005em;

  ${(props) => props.centered && 'text-align: center;'}
`;
